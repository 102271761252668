import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { EmptyComponent } from './components/empty/empty.component';
import { AppMainComponent } from './app.main.component';
import { ManageClientsComponent } from './components/manage-clients/manage-clients.component';
import { ManageLocationsComponent } from './components/manage-locations/manage-locations.component';
import { ManageFleetsComponent } from './components/manage-fleets/manage-fleets.component';
import { ManageUsersComponent } from './components/manage-users/manage-users.component';
import { ManageRolesComponent } from './components/manage-roles/manage-roles.component';
import { AddLocationComponent } from './components/add-location/add-location.component';
import { AddClientComponent } from './components/add-client/add-client.component';
import { ManageSpotsComponent } from './components/manage-spots/manage-spots.component';
import { AddFleetComponent } from './components/add-fleet/add-fleet.component';
import { AddUserComponent } from './components/add-user/add-user.component';
import { LoginComponent } from './components/login/login.component';
import { ClientDetailsComponent } from './components/client-details/client-details.component';
import { AddJobComponent } from './components/add-job/add-job.component';
import { ManageJobsComponent } from './components/manage-jobs/manage-jobs.component';
import { ManageMessagesComponent } from './components/manage-messages/manage-messages.component';
import { ManageEntryExitComponent } from './components/manage-entry-exit/manage-entry-exit.component';
import { AuthGuard } from './security/auth.guard';
import { CanvasToolComponent } from './canvas-tool/canvas-tool.component';
import { PreviewCanvasMapComponent } from './preview-canvas-map/preview-canvas-map.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { NewUserComponent } from './components/new-user/new-user.component';
import { ManageTrailerAuditComponent } from './components/manage-trailer-audit/manage-trailer-audit.component';
import { AddTrailerAuditComponent } from './components/add-trailer-audit/add-trailer-audit.component';
import { TermsComponent } from './components/terms/terms.component';
import { UserTermsComponent } from './components/user-terms/user-terms.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { UserprivacyPolicyComponent } from './components/userprivacy-policy/userprivacy-policy.component';
import { RedirectPageComponent } from './components/redirect-page/redirect-page.component';
import { OverviewComponent } from './components/overview/overview.component';
import { ManageMobileAppVersionComponent } from './components/manage-mobile-app-version/manage-mobile-app-version.component';
import { ManageUserAvailabilityComponent } from './components/manage-user-availability/manage-user-availability.component';
import { ManageOvertimeUsersComponent } from './components/manage-overtime-users/manage-overtime-users.component';
@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', redirectTo : 'login', pathMatch :'full',
            },
            { path:'login', component:LoginComponent},
            { path:'forgot-password', component:ForgotPasswordComponent},
            {path:'new-user', component:NewUserComponent},
            {path:'terms', component:TermsComponent},
            {path:'privacy-policy', component:PrivacyPolicyComponent},
            {path:'saml2-success', component:RedirectPageComponent},

            {
                path: 'main', component: AppMainComponent, canActivate:[AuthGuard],
                children: [
                    {path: '', component: DashboardComponent, canActivate:[AuthGuard],data: { roles: ["ROLE_IT","ROLE_ADMIN","ROLE_CLIENT","ROLE_SUPERVISOR","ROLE_DRIVER","ROLE_GUARD","ROLE_SPOTTER"] }},
                    {path: 'pages/empty', component: EmptyComponent, canActivate:[AuthGuard],data: { roles: ["ROLE_IT","ROLE_ADMIN","ROLE_CLIENT","ROLE_SUPERVISOR"] }},
                    {path: 'manage-clients', component: ManageClientsComponent, canActivate:[AuthGuard],data: { roles: ["ROLE_IT","ROLE_ADMIN","ROLE_CLIENT","ROLE_SUPERVISOR","ROLE_DRIVER","ROLE_GUARD","ROLE_SPOTTER"] }},
                    {path: 'manage-locations', component: ManageLocationsComponent, canActivate:[AuthGuard],data: { roles: ["ROLE_IT","ROLE_ADMIN","ROLE_CLIENT","ROLE_SUPERVISOR","ROLE_DRIVER","ROLE_GUARD","ROLE_SPOTTER"] }},
                    {path: 'manage-fleets', component: ManageFleetsComponent, canActivate:[AuthGuard],data: { roles: ["ROLE_IT","ROLE_ADMIN","ROLE_CLIENT","ROLE_SUPERVISOR","ROLE_DRIVER","ROLE_GUARD","ROLE_SPOTTER"] }},
                    {path: 'manage-users', component: ManageUsersComponent,canActivate:[AuthGuard],data: { roles: ["ROLE_IT","ROLE_ADMIN","ROLE_SUPERVISOR","ROLE_DRIVER","ROLE_GUARD","ROLE_SPOTTER","ROLE_CLIENT"] }},
                    {path: 'manage-roles', component: ManageRolesComponent, canActivate:[AuthGuard],data: { roles: ["ROLE_IT","ROLE_ADMIN"] }},
                    {path: 'manage-spots', component: ManageSpotsComponent, canActivate:[AuthGuard],data: { roles: ["ROLE_IT","ROLE_ADMIN","ROLE_SUPERVISOR","ROLE_DRIVER","ROLE_GUARD","ROLE_SPOTTER","ROLE_CLIENT"] }},
                    {path: 'add-client', component: AddClientComponent, canActivate:[AuthGuard],data: { roles: ["ROLE_IT","ROLE_ADMIN"] }},
                    {path: 'add-location', component: AddLocationComponent, canActivate:[AuthGuard],data: { roles: ["ROLE_IT","ROLE_ADMIN"] }},
                    {path: 'add-fleet', component: AddFleetComponent, canActivate:[AuthGuard],data: { roles: ["ROLE_IT","ROLE_ADMIN","ROLE_SUPERVISOR","ROLE_GUARD","ROLE_SPOTTER", "ROLE_CLIENT"] }},
                    {path: 'add-user', component: AddUserComponent,canActivate:[AuthGuard],data: { roles: ["ROLE_IT","ROLE_ADMIN","ROLE_CLIENT","ROLE_SUPERVISOR","ROLE_DRIVER","ROLE_GUARD","ROLE_SPOTTER"] }},
                    {path: 'client-details', component: ClientDetailsComponent, canActivate:[AuthGuard],data: { roles: ["ROLE_IT","ROLE_ADMIN","ROLE_CLIENT","ROLE_SUPERVISOR","ROLE_DRIVER","ROLE_GUARD","ROLE_SPOTTER"] }},
                    {path: 'add-job', component: AddJobComponent, canActivate:[AuthGuard],data: { roles: ["ROLE_IT","ROLE_ADMIN","ROLE_SUPERVISOR","ROLE_DRIVER","ROLE_GUARD","ROLE_SPOTTER","ROLE_CLIENT"] }},
                    {path: 'manage-jobs', component: ManageJobsComponent, canActivate:[AuthGuard],data: { roles: ["ROLE_IT","ROLE_ADMIN","ROLE_CLIENT","ROLE_SUPERVISOR","ROLE_DRIVER","ROLE_GUARD","ROLE_SPOTTER"] }},
                    {path: 'manage-messages', component: ManageMessagesComponent, canActivate:[AuthGuard],data: { roles: ["ROLE_IT","ROLE_ADMIN","ROLE_CLIENT","ROLE_SUPERVISOR","ROLE_DRIVER","ROLE_GUARD","ROLE_SPOTTER"] }},
                    {path: 'manage-entry-exit', component: ManageEntryExitComponent, canActivate:[AuthGuard],data: { roles: ["ROLE_IT","ROLE_ADMIN","ROLE_CLIENT","ROLE_SUPERVISOR","ROLE_GUARD"] }},
                    {path: 'manage-trailer-audit', component: ManageTrailerAuditComponent, canActivate:[AuthGuard],data: { roles: ["ROLE_CLIENT","ROLE_SUPERVISOR"] }},
                    {path: 'add-trailer-audit', component: AddTrailerAuditComponent, canActivate:[AuthGuard],data: { roles: ["ROLE_SUPERVISOR"] }},
                    {path:'user-terms',component: UserTermsComponent, canActivate:[AuthGuard],data: { roles: ["ROLE_IT","ROLE_ADMIN","ROLE_CLIENT","ROLE_SUPERVISOR","ROLE_DRIVER","ROLE_GUARD","ROLE_SPOTTER"] }},
                    {path:'user-privacy-policy',component: UserprivacyPolicyComponent, canActivate:[AuthGuard],data: { roles: ["ROLE_IT","ROLE_ADMIN","ROLE_CLIENT","ROLE_SUPERVISOR","ROLE_DRIVER","ROLE_GUARD","ROLE_SPOTTER"] }},
                    {path:'overview',component: OverviewComponent, canActivate:[AuthGuard],data: { roles: ["ROLE_ADMIN","ROLE_CLIENT","ROLE_SUPERVISOR"] }},
                    {path:'manage-mobile-app',component: ManageMobileAppVersionComponent, canActivate:[AuthGuard],data: { roles: ["ROLE_ADMIN","ROLE_IT"] }},
                    {path:'manage-user-availability',component: ManageUserAvailabilityComponent, canActivate:[AuthGuard],data: { roles: ["ROLE_IT","ROLE_ADMIN","ROLE_SUPERVISOR"] }},
                    {path:'manage-overtime-users',component: ManageOvertimeUsersComponent, canActivate:[AuthGuard],data: { roles: ["ROLE_IT","ROLE_ADMIN","ROLE_SUPERVISOR"] }}
                   

                ]

            },
            {path: 'canvas-tool', component:CanvasToolComponent, canActivate:[AuthGuard],data: { roles: ["ROLE_IT"] }},
            {path: 'preview-map', component:PreviewCanvasMapComponent, canActivate:[AuthGuard]},

            {path: '**', redirectTo: 'main/pages/empty'},
        ], {scrollPositionRestoration: 'enabled'})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
