<div class="grid">
    <div class="col-12">
        <div class="card card-w-title">
            <p-breadcrumb [model]="breadcrumbItems" [home]="{icon: 'pi pi-home',routerLink:'../'}"></p-breadcrumb>
        </div>
    </div>
    <div class="col-12">
        <div class="card">
            <p-toast></p-toast>
            <p-toolbar styleClass="mb-4">
                <ng-template pTemplate="left">
                    <div class="my-2">
                        <h5 class="m-0">User Availability</h5>
                    </div>
                </ng-template>
                <ng-template pTemplate="right">
                    <div class="">
                        <!-- <h5>User Details</h5> -->
                        <div class="flex" *ngIf="name != undefined && name != ''">
                            <h6 class="mr-2">Name: </h6>
                            {{ name }}
                        </div>
                        <div class="flex" *ngIf="email != undefined && email != ''">
                            <h6 class="mr-2">Email: </h6>
                            {{ email }}
                        </div>
                    </div>
                </ng-template>
            </p-toolbar>
           

            <div class="user-avail-grid grid">
                <div class="col-5 card">
                    <div class="card1">
                        <!-- <h5>Availability</h5>
                        <hr /> -->
                        <div class="field d-flex">
                            <div class="col-3">
                                <h6>Day</h6>
                            </div>
                            <div class="col-3">
                                <h6>Start Time</h6>
                            </div>
                            <div class="col-3">
                                <h6>End Time</h6>
                            </div>
                            <div class="col-3 text-center">
                                <h6>Available</h6>
                            </div>
                        </div>
                        <hr />
                        <div class="field d-flex" *ngFor="let item of availabilityList">
                            <div class="col-3">
                                <label>{{item.dayOfWeek}}</label>
                            </div>
                            <div class="col-3">
                                <p-calendar inputId="calendar-timeonly" [(ngModel)]="item.startingTime"
                                    [timeOnly]="true" hourFormat="12" (ngModelChange)="availabilityChanged(item)"></p-calendar>
                            </div>
                            <div class="col-3">
                                <p-calendar inputId="calendar-timeonly" [(ngModel)]="item.endingTime"
                                    [timeOnly]="true" hourFormat="12" (ngModelChange)="availabilityChanged(item)"></p-calendar>
                                <span class="text-danger" *ngIf="item.isRequiredError">Please provide both starting and ending time.</span>
                            </div>
                            <div class="col-3 text-center">
                                <p-checkbox [(ngModel)]="item.isActive" [binary]="true" inputId="binary"
                                    (click)="availabilityActivated(item)"
                                    [disabled]="item.endingTime == null || item.startingTime == null"></p-checkbox>
                            </div>
                        </div>
                        <div class="field flex justify-content-end">
                            <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-success"
                        (click)="saveUserAvailability()"></button>
                        </div>
                    </div>
                </div>
                <div class="col-7 card">
                    <div class="card1">
                        <!-- <h5>Time Off / Working Day</h5> -->
                        <!-- <hr/> -->
                        <p-table #dt [value]="userAvailabilityException"
                            styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" [responsive]="true"
                            [rows]="50" [rowHover]="true" dataKey="id">
                            <ng-template pTemplate="caption">
                                <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                                  <h5 class="m-0">Time Off / Working Day</h5>
                                  <div class="my-2 flex align-items-baseline">
                                    <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success"
                                        (click)="newOffDayDialog = true"></button>
                                  </div>
                                </div>
                              </ng-template>
                           
                            <ng-template pTemplate="header">
                                <tr>

                                    <th pSortableColumn="date">Date</th>
                                    <th pSortableColumn="type">Type of day</th>
                                    <th pSortableColumn="startingTime">Start Time</th>
                                    <th pSortableColumn="endingTime">End Time</th>
                                    <th></th>

                                </tr>


                            </ng-template>

                            <ng-template pTemplate="body" let-item>
                                <tr>
                                    <td>
                                        <span class="p-column-title">Date</span>
                                        {{item.date}}
                                    </td>

                                    <td>
                                        <span class="p-column-title">Type of Day</span>
                                        {{item.type}}
                                    </td>

                                    <td>
                                        <span class="p-column-title">Start Time</span>
                                        {{item.startingTime}}
                                    </td>

                                    <td>
                                        <span class="p-column-title">End Time</span>
                                        {{item.endingTime}}
                                    </td>
                                    <td>
                                        <button pButton type="button" class="p-button-help"
                                            icon="pi pi-ellipsis-h" (click)="menu.toggle($event);toggleMenu(item)"></button>
                                            <p-menu #menu [popup]="true" [model]="menuItems" appendTo="body"></p-menu>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="7">No Data found.</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
            <p-dialog [(visible)]="newOffDayDialog" header="Details" [modal]="true" (onHide)="hideDialog()"
                [style]="{width:'550px',height: '600px'}">
                <ng-template pTemplate="content">
                    <form [formGroup]="newExceptionForm">
                        <div class="p-fluid p-formgrid grid">
                            <div class="col-12 md:col-12 field">
                                <label>Date</label>
                                <p-calendar formControlName="date" appendTo="body"></p-calendar>
                                <span class="text-danger" *ngIf="
                                    newExceptionForm.controls.date.touched &&
                                    newExceptionForm.controls.date.invalid && isSubmitted
                                    ">Date is required</span>
                            </div>
                            <!-- <div class="field col-12 md:col-12">
                                <label htmlFor="date">Date<span class="text-danger">*</span></label>
                                <p-calendar ></p-calendar>
                                <p-dropdown placeholder="Select Date" formControlName="clientId" [options]="clientList" (onChange)="onClientSelect($event)" optionLabel="clientName" optionValue="clientId"></p-dropdown>
                                <span
                                    class="text-danger"
                                    *ngIf="
                                    jobForm.controls.clientId.touched &&
                                    jobForm.controls.clientId.invalid
                                    "
                                    >Client is required</span
                                    >
                            </div> -->
                            <div class="col-12 md:col-6 field">
                                <label>Start Time</label>
                                <p-calendar inputId="calendar-timeonly" formControlName="startingTime"
                                    [timeOnly]="true" hourFormat="12"></p-calendar>
                                <span class="text-danger" *ngIf="
                                newExceptionForm.controls.startingTime.touched &&
                                newExceptionForm.controls.startingTime.invalid && isSubmitted
                                ">Start Time is required</span>
                            </div>
                            <div class="col-12 md:col-6 field">
                                <label>End Time</label>
                                <p-calendar inputId="calendar-timeonly" formControlName="endingTime"
                                    [timeOnly]="true" hourFormat="12"></p-calendar>
                                <span class="text-danger" *ngIf="
                                newExceptionForm.controls.endingTime.touched &&
                                newExceptionForm.controls.endingTime.invalid && isSubmitted
                                ">End Time is required</span>
                            </div>
                            <div class="col-12 md:col-12 field">
                                <label>Type of day</label>
                                <p-dropdown placeholder="Select Type" formControlName="type" [options]="type"
                                    optionLabel="name" optionValue="code"></p-dropdown>
                                <span class="text-danger" *ngIf="
                                newExceptionForm.controls.type.touched &&
                                newExceptionForm.controls.type.invalid && isSubmitted
                                ">Type is required</span>
                            </div>
                        </div>
                    </form>
                </ng-template>
                <ng-template pTemplate="footer">
                    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-secondary"
                        (click)="hideDialog()"></button>
                    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-success"
                        (click)="saveNewException()"></button>
                </ng-template>
            </p-dialog>

        </div>
    </div>
</div>