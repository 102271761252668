<div class="grid">
    <div class="col-12">
        <div class="card card-w-title">
            <p-breadcrumb [model]="breadcrumbItems" [home]="{icon: 'pi pi-home',routerLink:'../'}"></p-breadcrumb>
        </div>
    </div>
    <div class="col-12">
		<div class="card">
            <h4>Create Trailer Audit</h4>
            <form [formGroup]="trailerAuditForm">
                <div class="p-fluid p-formgrid grid">
                    <div class="field col-12 md:col-4">
                        <label htmlFor="trailerTrucks">Trailer<span class="text-danger">*</span></label>
                        <p-autoComplete formControlName="fleetId"  [suggestions]="filteredFleets" (onSelect)="onFleetSelect($event)" dataKey="fleetId" (completeMethod)="filterFleets($event)" [dropdown]="true" (onClear)="clearUniqueId($event)">
                            <ng-template let-fleet pTemplate="item">
                                <div>{{fleet.fleetAndHotTrailer}}</div>
                            </ng-template>
                        </p-autoComplete>
                           <span
                            class="text-danger"
                            *ngIf="
                            trailerAuditForm.controls.fleetId.touched &&
                            trailerAuditForm.controls.fleetId.invalid "
                            >Trailer/Truck/Container is required</span
                            > 
                    </div>
                    <div class="field col-12 md:col-4">
                        <label htmlFor="pickupLocation">Area<span class="text-danger">*</span></label>
                        <p-dropdown [options]="clientsLocationsList"   placeholder="Select Pickup Location" optionLabel="locationName"  optionValue="" (onChange)="loadLocationSpots($event)"></p-dropdown>
                        <span
                            class="text-danger"
                            *ngIf="
                            trailerAuditForm.controls.area.touched &&
                            trailerAuditForm.controls.area.invalid
                            "
                            >Area is required</span
                            >
                    </div>
                    <div class="field col-12 md:col-4">
                        <label htmlFor="pickupSpot">Slot<span class="text-danger">*</span></label>
                        <p-dropdown [options]="spots"  placeholder="Select Pickup Spot" optionLabel="spotName" optionValue="" (onChange)="patchSpotName($event)"></p-dropdown>
                        <span
                            class="text-danger"
                            *ngIf="
                            trailerAuditForm.controls.slot.touched &&
                            trailerAuditForm.controls.slot.invalid
                            "
                            >Slot is required</span
                            >
                    </div>
                    <div class="field col-12 md:col-6">
                        <label htmlFor="trailerTrucks">Trailer Status<span class="text-danger">*</span></label>
                        <p-dropdown [options]="trailerStatus" formControlName="trailerStatus" placeholder="Select Trailer/Container Status" optionLabel="name" optionValue="code"></p-dropdown>
                        <span
                        class="text-danger"
                        *ngIf="
                        trailerAuditForm.controls.trailerStatus.touched &&
                        trailerAuditForm.controls.trailerStatus.invalid
                        "
                        >Slot is required</span
                        >
                    </div>
                    <div class="field col-12 md:col-6">
                        <label htmlFor="carrier">Carrier</label>
                        <textarea pInputTextarea autoResize rows="1" cols="10" formControlName="carrier" [(ngModel)]="carrier"></textarea>
                        
                           <span
                            class="text-danger"
                            *ngIf="
                            trailerAuditForm.controls.carrier.touched &&
                            trailerAuditForm.controls.carrier.invalid "
                            >Carrier is required</span
                            > 
                    </div>
                    <div class="field col-12">
                        <label htmlFor="jobDescription">Notes</label>
                        <textarea pInputTextarea autoResize rows="3" cols="30" formControlName="notes"></textarea>
                    </div>
                        <button  pButton  type="button" (click)="onSubmit()" label="Submit"></button>
                    </div>
            </form>
        </div>
    </div>
</div>
