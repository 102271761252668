import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { AppLoaderService } from "src/app/app-loader/service/app-loader.service";
import { APP_CONSTANTS } from "src/app/constants/app.constants";
import { ErrorService } from "src/app/error-handling/error.service";
import { AuthenticationService } from "src/app/security/authentication.service";
import { TokenService } from "src/app/security/token.service";
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  ssoPageUrl:string = APP_CONSTANTS.BASE_API_URL + "/saml2/authenticate/azure";
  authenticateSubscription: Subscription;
  loginForm: FormGroup;
  isLogin: boolean;
  // UfovwgGe
  constructor(
    private router: Router,
    private tokenService: TokenService,
    private loader: AppLoaderService,
    private authenticationService: AuthenticationService,
    private errorService: ErrorService
  ) {
    this.loginForm = new FormGroup({
      userName: new FormControl("", [Validators.required, Validators.email]),
      password: new FormControl("", [Validators.required]),
    });
  }
  ngOnInit(): void {
    this.isLogin = false;
    if (this.tokenService.getAccessToken() != undefined) {
      this.router.navigateByUrl("main");
    }
  }
  login() {
    if (this.loginForm.invalid) {
      this.loginForm.markAllAsTouched();
    } else {
      this.loader.show();
      this.authenticateSubscription = this.authenticationService
        .authenticate({
          grant_type: "password",
          username: this.loginForm.value.userName,
          password: this.loginForm.value.password,
        })
        .subscribe(
          (token) => {
            console.log("token",token);
            // Depending on the role redirect user to appropriate landing page
            const authorities = JSON.parse(token)["roles"];
            if (
              authorities.some(
                (role) =>
                  role === APP_CONSTANTS.USER_ROLES.ROLE_IT ||
                  role === APP_CONSTANTS.USER_ROLES.ROLE_CLIENT ||
                  role === APP_CONSTANTS.USER_ROLES.ROLE_ADMIN ||
                  role === APP_CONSTANTS.USER_ROLES.ROLE_SUPERVISOR
              )
            ) {
              // Save token in local storage
              // const accessToken = JSON.parse(token)['access_token'];
              this.tokenService.saveToken(token);
              //this.isLogin=true;
              this.isLogin = true;
              setTimeout(() => {
                console.log("sleep");
                this.router.navigate(["main"]);
                // And any other code that should run only after 5s
              }, 2000);
              //this.router.navigate(['main'])
            } else if (
              authorities.some(
                (role) =>
                  role === APP_CONSTANTS.USER_ROLES.ROLE_DRIVER ||
                  role === APP_CONSTANTS.USER_ROLES.ROLE_SPOTTER ||
                  role === APP_CONSTANTS.USER_ROLES.ROLE_GUARD
              )
            ) {
              this.tokenService.saveToken(token);
              this.isLogin = true;
              setTimeout(() => {
                console.log("sleep");
                this.router.navigate(["main/manage-clients"]);
                // And any other code that should run only after 5s
              }, 2000);
              //this.router.navigate(['main/manage-clients'])
            }
            this.loader.hide();
          },
          (error) => {
            this.loader.hide();
            this.errorService.handleError(error, true);
          }
        );
    }
  }
}
