import { Component, OnDestroy, OnInit } from '@angular/core';
import { Product } from '../../api/product';
import { ProductService } from '../../service/productservice';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { ActivatedRoute } from '@angular/router';
import { IpagedQuery } from 'src/app/model/IpagedQuery';
import { Subscription } from 'rxjs';
import { AppLoaderService } from 'src/app/app-loader/service/app-loader.service';
import { AppAlertService } from 'src/app/app-alert/service/app-alert.service';
import { ManageSpotsService } from './manage-spots.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { APP_CONSTANTS } from 'src/app/constants/app.constants';
import { TokenService } from 'src/app/security/token.service';
import { ErrorService } from 'src/app/error-handling/error.service';

@Component({
  selector: 'app-manage-spots',
  templateUrl: './manage-spots.component.html',
  providers: [MessageService, ConfirmationService],
  styleUrls: ['../../../assets/demo/badges.scss','./manage-spots.component.scss']
})
export class ManageSpotsComponent implements OnInit, OnDestroy {

  breadcrumbItems: MenuItem[];
  spotDialog: boolean;
  deleteSpotDialog: boolean = false;

//     products = [
//       {
//           id:"1",
//           name: 'Spot 1',
//           type: 'Spot',
//           status: 'Active',
//           latitude: '12345678',
//           longitude: '12345678',
//           remarks: 'Remarks',
//       },
//       {
//           id:"2",
//           name: 'Dock 2',
//           type: 'Dock',
//           status: 'Active',
//           latitude: '12345678',
//           longitude: '12345678',
//           remarks: 'Remarks',
//       },
//       {
//           id:"3",
//           name: 'Spot 3',
//           type: 'Spot',
//           status: 'Active',
//           latitude: '12345678',
//           longitude: '12345678',
//           remarks: 'Remarks',
//       },
//       {
//           id:"4",
//           name: 'Dock 4',
//           type: 'Dock',
//           status: 'Active',
//           latitude: '12345678',
//           longitude: '12345678',
//           remarks: 'Remarks',
//       },
//   ];

    spotList = [];
    cols: any[];
    statuses: any[];
    type: any[];
    spot:Spot;
    clientId: any;
    clientName: any;
    locationId: any;
    locationName: any;
    loading: boolean;
    query:IpagedQuery;
    totalRecords: any;
    subscription = new Subscription();
    spotForm: FormGroup;
    fromDetails: any;
    userRoles: any;
    userType: any;
    accessToken: string;
    items: MenuItem[];
    hideButtonsIfDriver:boolean = false;
    hideButtonsIfSupervisor:boolean = false;
    hideButtonsIfGuard:boolean = false;
    hideButtonsIfSpotter:boolean = false;

    constructor(private activatedRoute:ActivatedRoute,
        private loader:AppLoaderService,
        private alertService:AppAlertService,
        private manageSpotsService:ManageSpotsService,
        private fb:FormBuilder,
        private tokenService:TokenService,
        private errorService:ErrorService
        ) {
            this.spotForm = this.fb.group({
                latitude: [0],
                locationId: ['',Validators.required],
                longitude: [0],
                remarks: [''],
                spotName: ['',Validators.required],
                status: ['',Validators.required],
                type: ['',Validators.required],
                locationName:['',Validators.required]
            });

        this.activatedRoute.queryParams.subscribe(qparams=>{
            if(qparams["clientId"] && qparams["clientName"] && qparams["locationId"] && qparams["locationName"]){
                this.clientId = qparams["clientId"];
                this.clientName = qparams["clientName"];
                this.locationId = qparams["locationId"];
                this.locationName = qparams["locationName"];
            }
            if(qparams['fromDetails']){
                this.fromDetails = qparams['fromDetails']
            }
        });


      

        this.items = [
            {
                label: 'Excel',
                icon: 'pi pi-download',
                command: () => {
                    this.exportExcel();
                }
            },
            {
                label: 'CSV',
                icon: 'pi pi-download',
                command: () => {
                    this.exportCsv();
                }
            }
        ];

    }

    ngOnInit() {

        this.userRoles = this.tokenService.getUserRoles();
        if (this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_CLIENT && role !== APP_CONSTANTS.USER_ROLES.ROLE_ADMIN && role !== APP_CONSTANTS.USER_ROLES.ROLE_IT)){
            this.userType = APP_CONSTANTS.USER_ROLES.ROLE_CLIENT;
        }
        if(this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_DRIVER))
        {
            this.hideButtonsIfDriver = true;
        } 
        if(this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_SUPERVISOR))
        {
            this.hideButtonsIfSupervisor = true;
        }
        if(this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_GUARD))
        {
            this.hideButtonsIfGuard = true;
        }
        if(this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_SPOTTER))
        {
            this.hideButtonsIfSpotter = true;
        }
        this.accessToken = this.tokenService.getAccessToken();
        
        this.query = {isActive:true,size:10,page:0};

        this.cols = [
            {field: 'name', header: 'Name'},
            {field: 'type', header: 'Type'},
            {field: 'status', header: 'Status'},
            {field: 'latitude', header: 'Latitude'},
            {field: 'longitude', header: 'Longitude'}
        ];

        this.type = [
          {name: 'Dock', code: 'DOCK'},
          {name: 'Parking Spot', code: 'SPOT'},
          {name: 'Curb', code: 'CURB'},
        ];

        this.statuses = [
          {name: 'Empty', code: 'EMPTY'},
          {name: 'Occupied', code: 'OCCUPIED'},
          {name: 'To Be Empty', code: 'TO_BE_EMPTY'},
          {name: 'To Be Occupied', code: 'TO_BE_OCCUPIED'},
          
        ];

        this.breadcrumbItems = [];
        if(!this.fromDetails){
            this.breadcrumbItems.push({ label: 'Clients',routerLink:'../manage-clients'});
            this.breadcrumbItems.push({ label: 'Locations',routerLink:'../manage-locations', queryParams:{clientId:this.clientId,clientName:this.clientName,locationId:this.locationId}});
            this.breadcrumbItems.push({ label: 'Docks/Parking Spots'});
        }else {
            this.breadcrumbItems.push({ label: 'Clients',routerLink:'../manage-clients'});
            this.breadcrumbItems.push({ label: 'Client Locations', routerLink : '../client-details', queryParams : {clientId : this.clientId}});
            this.breadcrumbItems.push({ label: 'Docks/Parking Spots'});

        }
     

        if(this.clientId){
            this.getClientSpots(this.query,this.clientId,this.locationId);
        }
    }

    getClientSpots(query,clientId,locationId) {
        this.loading=true;
        this.subscription.add(
            this.manageSpotsService.viewSpots(query,clientId,locationId).subscribe(response=>{
                this.spotList = response.list;
                this.totalRecords = response.totalElements;
                this.loading = false;
            },(error) => {
                this.loader.hide();
                this.errorService.handleError(error, true);
            })
        )
    }

    openNew() {
        this.spot = undefined;
        this.spotDialog = true;
    }

    editSpot(spot) {
        this.spot = {...spot};
        this.spotForm.patchValue(this.spot);
        this.spotDialog = true;
    }

    deleteSpot(spot) {
        this.deleteSpotDialog = true;
        this.spot = {...spot};
    }

    confirmDelete(){
        this.loader.show();
        this.subscription.add(
            this.manageSpotsService.deleteSpot(this.clientId,this.spot.spotId).subscribe(res=>{
                this.alertService.alertSuccess(['Dock/Parking Spot Deleted/Deactivate Successfully']);
                this.getClientSpots(this.query,this.clientId,this.locationId);
                this.loader.hide();
                this.deleteSpotDialog = false;
            },(error) => {
                this.loader.hide();
                this.errorService.handleError(error, true);
            })
        )
        this.spot = undefined;
    }

    hideDialog() {
        this.spotDialog = false;
    }

    saveSpot() {
        this.spotForm.patchValue({
            locationId:this.locationId,
            locationName:this.locationName
        })
        if(this.spotForm.invalid){
            this.spotForm.markAllAsTouched();
        }else{
            this.loader.show();
            if(this.spot !== undefined){
              this.manageSpotsService.updateSpot(this.clientId,this.spot.spotId,this.spotForm.value).subscribe(res=>{
                this.loader.hide();
                this.alertService.alertSuccess(['Dock/Parking Spot Updated Successfully']);
                this.getClientSpots(this.query,this.clientId,this.locationId);
                this.spotDialog = false;
                this.spotForm.reset();
                this.spotForm.patchValue({
                    latitude:0,
                    longitude:0,
                })
              },(error) => {
                this.loader.hide();
                this.errorService.handleError(error, true);
            })
            }else{
              this.manageSpotsService.saveSpot(this.clientId,this.spotForm.value).subscribe(res=>{
                this.loader.hide();
                this.alertService.alertSuccess(['Dock/Parking Spot Added Successfully']);
                this.getClientSpots(this.query,this.clientId,this.locationId);
                this.spotDialog = false;
                this.spotForm.reset();
                this.spotForm.patchValue({
                    latitude:0,
                    longitude:0,
                })
              },(error) => {
                this.loader.hide();
                this.errorService.handleError(error, true);
            })
            }
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    paginate(event){
        this.query.page = event.page;
        this.getClientSpots(this.query,this.clientId,this.locationId);
    }

    exportCsv(){
        window.open(`${APP_CONSTANTS.BASE_API_URL}/v1/clients/${this.clientId}/spots/export/csv?access_token=${this.accessToken}`, '_blank');
    }
    
    exportExcel(){
        window.open(`${APP_CONSTANTS.BASE_API_URL}/v1/clients/${this.clientId}/spots/export/excel?access_token=${this.accessToken}`, '_blank');
    }
}

export interface Spot{
    spotId?:string,
    latitude: number,
    locationId: string,
    longitude: number,
    remarks: string,
    spotName: string,
    status: string,
    type: string
}
