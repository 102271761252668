import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { MenuItem } from 'primeng/api';
import { map, mergeMap, Subscription } from 'rxjs';
import { Product } from 'src/app/api/product';
import { AppLoaderService } from 'src/app/app-loader/service/app-loader.service';
import { APP_CONSTANTS } from 'src/app/constants/app.constants';
import { ErrorService } from 'src/app/error-handling/error.service';
import { TokenService } from 'src/app/security/token.service';
import { ProductService } from 'src/app/service/productservice';
import { AddClientService } from '../add-client/add-client.service';
import { ManageLocationsService } from '../manage-locations/manage-locations.service';
import { ManageSpotsService } from '../manage-spots/manage-spots.service';
import { DataService } from 'src/app/service/data.service';
declare const google: any;

@Component({
  selector: 'app-client-details',
  templateUrl: './client-details.component.html',
  styleUrls: ['./client-details.component.scss']
})
export class ClientDetailsComponent implements OnInit, OnDestroy {

  lat = 38.1528549;
  lng = -85.8892582;
  breadcrumbItems: MenuItem[];
  selectedLocation;
  locations = [];
  spotsArray = [];
  zoom: any = 19;
  mapType = "satellite";
  clientId: any;
  query: { isActive: boolean; size: number; page: number; };
  subscription = new Subscription();
  client: any;
  showChildren;
  manageLocationId: any;
  statuses: { name: string; code: string; }[];
  filterByStatus: any;
  trailerStatus: { name: string; code: string; }[];
  userRoles: any;
  userType: any;
  spotsFilteredArray: any[];
  defaultLocations: any;
  interval: number;
  tallyObj;
  hideButtonsIfDriver:boolean = false;
  hideButtonsIfSupervisor:boolean = false;
  hideButtonsIfGuard:boolean = false;
  hideButtonsIfSpotter:boolean = false;
  protected subscriptions: Subscription[] = [];
 

  constructor(private router:Router,
    private activatedRoute:ActivatedRoute,
    private loader:AppLoaderService,
    private manageLocationsService:ManageLocationsService,
    private addClientService:AddClientService,
    private manageSpotsServie:ManageSpotsService,
    private changeDetector: ChangeDetectorRef,
    private tokenService:TokenService,
    private errorService:ErrorService,
    private dataService: DataService
  ) {
    this.activatedRoute.queryParams.subscribe(qparams=>{
      if(qparams["clientId"]){
          this.clientId = qparams["clientId"];
      }
      if(qparams["locationId"]){
        this.manageLocationId = qparams["locationId"];
    }
    })
   }

  ngOnDestroy() {
  //  if (this.interval) {
  //    clearInterval(this.interval);
  //  }

  if (!this.subscriptions) {
    return;
  }
  this.subscriptions.forEach(s => s.unsubscribe());
  this.subscriptions = [];

  }

  ngOnInit(): void {
    this.userRoles = this.tokenService.getUserRoles();
      if (this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_CLIENT && role !== APP_CONSTANTS.USER_ROLES.ROLE_ADMIN && role !== APP_CONSTANTS.USER_ROLES.ROLE_IT)){
        this.userType = APP_CONSTANTS.USER_ROLES.ROLE_CLIENT;
      }
      if(this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_DRIVER))
      {
          this.hideButtonsIfDriver = true;
      }
      if(this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_SUPERVISOR))
      {
          this.userType = APP_CONSTANTS.USER_ROLES.ROLE_SUPERVISOR;
          this.hideButtonsIfSupervisor = true;
      }
      if(this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_GUARD))
      {
          this.hideButtonsIfGuard = true;
      }
      if(this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_SPOTTER))
      {
          this.hideButtonsIfSpotter = true;
      }
      
    this.query = {isActive:true,size:1000,page:0};
    this.breadcrumbItems = [];
        this.breadcrumbItems.push({ label: 'Clients',routerLink:'../manage-clients'});
        this.breadcrumbItems.push({ label: 'Client Locations'});
    if(this.clientId){
      this.getClientDetailsAndLocations(this.query,this.clientId);
    }   
    
    this.statuses = [
      {name: 'Empty', code: 'EMPTY'},
      {name: 'Occupied', code: 'OCCUPIED'},
      {name: 'To Be Empty', code: 'TO_BE_EMPTY'},
      {name: 'To Be Occupied', code: 'TO_BE_OCCUPIED'},  
    ];

    this.trailerStatus = [
      {name: 'Empty', code: 'EMPTY'},
      {name: 'Full', code: 'FULL'}
    ];

    // this.interval = setInterval(() => {
    //   window.location.reload()
    // }, 30000);
  }

  getClientDetailsAndLocations(query,clientId) {
    this.loader.show()
   this.subscription.add(
     this.addClientService.getClientById(clientId)
        .pipe(
          mergeMap((client)=>{
            return this.manageLocationsService.viewLocations(query,clientId)
            .pipe(
              map((locations)=>{
                return {
                  client,
                  locations
                }
              })
            )
          })
        )
        .subscribe(response=>{
          this.client = response.client;
          this.locations = response.locations.list;
          if(this.locations && this.locations.length > 0){
            this.defaultLocations = this.locations.find(el=> el.isDefault == true);
            if(this.manageLocationId){
              this.selectedLocation = this.locations.find(el=> el.locationId == this.manageLocationId);
            }else{
              this.selectedLocation = this.locations.find(el=> el.isDefault == true);
            }
            if(this.selectedLocation){
           const subscription1 =  this.manageSpotsServie.viewSpots(query,clientId,this.selectedLocation.locationId)
              .pipe(
                map(spots=>{
                  let spotList = [];
                  for(let spot of spots.list){
                    let obj = {
                      ...spot,
                      emptiedSinceSeconds: this.elapsedTime(spot.emptiedSinceSeconds),
                      occupiedSinceSeconds: this.elapsedTime(spot.occupiedSinceSeconds) 
                     // spot.emptiedSinceSeconds !== null ?  moment.utc(spot.emptiedSinceSeconds*1000).format('HH:mm:ss') : spot.emptiedSinceSeconds
                    }
                    spotList.push(obj);
                  }
                  return {
                    ...spots,
                    list:spotList
                  }
                })
              ).subscribe(spots=>{
                this.spotsArray = spots.list;
                this.tallyObj = spots.tally;
                this.spotsFilteredArray = JSON.parse(JSON.stringify(this.spotsArray));
              });
              this.subscriptions.push(subscription1);
              if(this.selectedLocation.locationMapJson){
                this.showChildren = true;
              }
            }
            this.lat = parseFloat(this.selectedLocation.latitude);
            this.lng = parseFloat(this.selectedLocation.longitude);
          }
          this.loader.hide();
        },(error) => {
          this.loader.hide();
          this.errorService.handleError(error, true);
      })
      
    )
    

  }

  showSpotsForLocation(location,el: HTMLElement){
    // el.scrollIntoView();
    this.selectedLocation = location;
    this.zoom = 15;
    if(this.selectedLocation){
      const subscription =  this.manageSpotsServie.viewSpots(this.query,this.clientId,this.selectedLocation.locationId)
      .pipe(
        map(spots=>{
          let spotList = [];
          for(let spot of spots.list){
            let obj = {
              ...spot,
              emptiedSinceSeconds: this.elapsedTime(spot.emptiedSinceSeconds),
              occupiedSinceSeconds: this.elapsedTime(spot.occupiedSinceSeconds) 
            }
            spotList.push(obj);
          }
          return {
            ...spots,
            list:spotList
          }
        })
      )
      .subscribe(spots=>{
        this.spotsArray = spots.list;
        this.tallyObj = spots.tally;
        this.spotsFilteredArray = JSON.parse(JSON.stringify(this.spotsArray));
      },(error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
      });
      this.subscriptions.push(subscription);
      this.showChildren = false;
      this.changeDetector.detectChanges();
      if(this.selectedLocation.locationMapJson){
        this.showChildren = true;
      }
    }
  }

  onMouseOver(infoWindow:any, $event: any) {
    infoWindow.open();
  }

  onMouseOut(infoWindow:any, $event: any) {
    infoWindow.close();
  }

  routeToAddClient(){
    this.router.navigate(['/main/add-client'])
  }

  routeToEditClient(){
    this.router.navigate(['/main/add-client'],{queryParams:{clientId:this.clientId,fromDetails:true}})
  }

  routeToAddLocation(){
    this.router.navigate(['/main/add-location'],{queryParams:{clientId:this.clientId,fromDetails:true}});
  }

  routeToEditLocation(locationId){
    this.router.navigate(['/main/add-location'],{queryParams:{clientId:this.clientId,locationId,fromDetails:true}});
  }
  routeToSpots(locationId,locationName){
    this.router.navigate(['/main/manage-spots'],{queryParams:{clientId:this.clientId,clientName:this.client.clientName,locationId,locationName, fromDetails : true}})
  }
  routeToAddJob(){
    let data = [
      { label: 'Clients',url:'../manage-clients'},
      { label: 'Client Locations', url: '../client-details', queryParams: { clientId: this.clientId, locationId: this.manageLocationId }}
    ]
    this.dataService.setData(data);
    this.router.navigate(['/main/add-job'], {queryParams:{locationBack:true}});
  }

  filterSpotsByStatus(event){
    this.loader.show();
    if(event.value !== null){
      this.spotsFilteredArray = this.spotsArray.filter(spot => spot.status && spot.status == event.value);
    }else{
      this.spotsFilteredArray = JSON.parse(JSON.stringify(this.spotsArray));
    }
    this.loader.hide();
    // this.filterByStatus = event.value;
    // this.manageSpotsServie.viewSpots(this.query,this.client.clientId,this.selectedLocation.locationId,event.value)
    // .pipe(
    //   map(spots=>{
    //     let spotList = [];
    //     for(let spot of spots.list){
    //       let obj = {
    //         ...spot,
    //         emptiedSinceSeconds: this.elapsedTime(spot.emptiedSinceSeconds)
    //       }
    //       spotList.push(obj);
    //     }
    //     return {
    //       ...spots,
    //       list:spotList
    //     }
    //   })
    // ).subscribe(spots=>{
    //   this.spotsArray = spots.list;
    //   this.tallyObj = spots.tally;
    //   this.spotsFilteredArray = JSON.parse(JSON.stringify(this.spotsArray));
    //   this.loader.hide();
    // },(error) => {
    //   this.loader.hide();
    //   this.errorService.handleError(error, true);
    // });
  }

  filterSpotsByTrailerStatus(event){
    this.loader.show();
    // this.filterByStatus = event.value;
    // this.manageSpotsServie.viewSpots(this.query,this.client.clientId,this.selectedLocation.locationId,null,event.value)
    // .pipe(
    //   map(spots=>{
    //     let spotList = [];
    //     for(let spot of spots.list){
    //       let obj = {
    //         ...spot,
    //         emptiedSinceSeconds: spot.emptiedSinceSeconds !== null ?  moment.utc(spot.emptiedSinceSeconds*1000).format('HH:mm:ss') : spot.emptiedSinceSeconds
    //       }
    //       spotList.push(obj);
    //     }
    //     return {
    //       ...spots,
    //       list:spotList
    //     }
    //   })
    // ).subscribe(spots=>{
    //   this.spotsArray = spots.list;
    //   this.loader.hide();
    // });

    if(event.value !== null){
        this.spotsFilteredArray = this.spotsArray.filter(spot => spot.fleet && spot.fleet.fleetStatus == event.value);
    }else{
        this.spotsFilteredArray = JSON.parse(JSON.stringify(this.spotsArray));
    }
    this.loader.hide();
  }

  //mapArr:any=[{color:'#057203',statusVal:'Occupied'},{color:'#e68a00',statusVal:'To Be Occupied'},{color:'#ffff00',statusVal:'To Be Empty'},{color:'#ffffff',statusVal:'Empty'}];
  mapArr:any=[{color:'#ff0000',statusVal:'Occupied Full Trailer'},{color:'#057203',statusVal:'Occupied Empty Trailer'},{color:'#e68a00',statusVal:'Location Reserved'},{color:"#ffff00",statusVal:"Scheduled for Pick-Up"},{color:'#ffffff',statusVal:'Empty'}];

  elapsedTime(elapsedTime){
    if(elapsedTime !== null){
      elapsedTime = parseInt(elapsedTime) //because moment js dont know to handle number in string format
      //  var hours = Math.floor(moment.duration(elapsedTime,'seconds').asHours()).toLocaleString();
      var days = Math.floor(moment.duration(elapsedTime, 'seconds').asDays()).toLocaleString();
      var hours = moment.duration(elapsedTime, 'seconds').hours().toLocaleString();  
      var minutes = moment.duration(elapsedTime,'seconds').minutes().toLocaleString();
      var seconds = moment.duration(elapsedTime,'seconds').seconds().toLocaleString();
      days = days.length == 1 ? "0"+days : days;
      hours = hours.length == 1 ? "0"+hours : hours;
      minutes = minutes.length == 1 ? "0"+minutes : minutes;
      seconds = seconds.length == 1 ? "0"+seconds : seconds;
      var format =days + ":" + hours + ":" + minutes + ":" + seconds;
      //Math.floor(moment.duration(seconds,'seconds').asHours()) + ':' + moment.duration(seconds,'seconds').minutes() + ':' + moment.duration(seconds,'seconds').seconds();
      return format;
    }else{
      return elapsedTime;
    }
  }
}
