<div class="grid">
	<div class="col-12">
        <div class="card card-w-title">
            <p-breadcrumb [model]="breadcrumbItems" [home]="{icon: 'pi pi-home',routerLink:'../'}"></p-breadcrumb>
        </div>
    </div>
	<div class="col-12">
		<div class="card">
			<h5>Add Spot</h5>
            <form [formGroup]="jobForm">
                <div class="p-fluid p-formgrid grid">
                    <div *ngIf="userType !== 'ROLE_CLIENT'" class="field col-12 md:col-12">
                        <label htmlFor="client">Select Client<span class="text-danger">*</span></label>
                        <p-dropdown placeholder="Select Client" formControlName="clientId" [options]="clientList" (onChange)="onClientSelect($event)" optionLabel="clientName" optionValue="clientId"></p-dropdown>
                        <span
                            class="text-danger"
                            *ngIf="
                            jobForm.controls.clientId.touched &&
                            jobForm.controls.clientId.invalid
                            "
                            >Client is required</span
                            >
                    </div>
                    <div class="field col-12 md:col-6">
                        <label htmlFor="pickupLocation">Pickup Location<span class="text-danger">*</span></label>
                        <p-dropdown [options]="clientsLocationsList" formControlName="pickupLocationId" placeholder="Select Pickup Location" optionLabel="locationName" (onChange)="onChange($event,'pickup')" optionValue="locationId"></p-dropdown>
                        <span
                            class="text-danger"
                            *ngIf="
                            jobForm.controls.pickupLocationId.touched &&
                            jobForm.controls.pickupLocationId.invalid
                            "
                            >Pickup Location is required</span
                            >
                    </div>
                    <div class="field col-12 md:col-6">
                        <label htmlFor="pickupSpot">Pickup Dock/Parking Spot<span class="text-danger">*</span></label>
                        <p-dropdown [options]="pickupSpots" formControlName="pickupSpotId" placeholder="Select Pickup Spot" optionLabel="spotAndStatus" optionValue="spotId"></p-dropdown>
                        <span
                            class="text-danger"
                            *ngIf="
                            jobForm.controls.pickupSpotId.touched &&
                            jobForm.controls.pickupSpotId.invalid
                            "
                            >Pickup Dock/Parking Spot is required</span
                            >
                    </div>
                    <div class="field col-12 md:col-6">
                        <label htmlFor="dropLocation">Drop Location<span class="text-danger">*</span></label>
                        <p-dropdown [options]="clientsLocationsList" formControlName="dropLocationId" placeholder="Select Drop Location" optionLabel="locationName" (onChange)="onChange($event,'drop')" optionValue="locationId"></p-dropdown>
                        <span
                            class="text-danger"
                            *ngIf="
                            jobForm.controls.dropLocationId.touched &&
                            jobForm.controls.dropLocationId.invalid
                            "
                            >Drop Location is required</span
                            >
                    </div>
                    <div class="field col-12 md:col-6">
                        <label htmlFor="dropSpot">Drop off Dock/Parking Spot</label>
                        <p-dropdown [options]="dropSpots" formControlName="dropSpotId" placeholder="Select Drop Spot" optionLabel="spotAndStatus" optionValue="spotId"></p-dropdown>
                    </div>
                    <div class="field col-12 md:col-6" *ngIf="isSupervisorOrClient">
                        <label htmlFor="sequenceAsn">Sequence ASN</label>
                        <input pInputText id="sequenceAsn" formControlName="sequenceAsn" type="text" />
                        <span
                        class="text-danger"
                        *ngIf="
                        jobForm.controls.sequenceAsn.touched &&
                        jobForm.controls.sequenceAsn.invalid
                        "
                        >Sequence ASN is required</span
                        >
                    </div>
						
                    <div class="field col-12">
                        <label htmlFor="jobDescription">Notes</label>
                        <textarea pInputTextarea autoResize rows="3" cols="30" formControlName="description"></textarea>
                    </div>
                    <div class="field col-12 md:col-4">
                        <label htmlFor="trailerTrucks">Trailer/Truck/Container<span class="text-danger">*</span></label>
                        <p-autoComplete formControlName="fleetAndHotTrailer" (onFocus)="isInputTouched=true" (onBlur)="isInputTouched=true" (input)="onUnitNumberChange($event)" [suggestions]="filteredFleets" (onSelect)="onFleetSelect($event)" dataKey="fleetAndHotTrailer" (completeMethod)="filterFleets($event)" [dropdown]="true" (onClear)="clearUniqueId($event)">
                            <ng-template let-fleet pTemplate="item">
                                <div>{{fleet.fleetAndHotTrailer}}</div>
                            </ng-template>
                        </p-autoComplete>
                        <input pInputText type="hidden" formControlName="fleetId" />
                           <span
                            class="text-danger"
                            *ngIf="
                            jobForm.controls.fleetId.touched &&
                            jobForm.controls.fleetId.invalid && 
                            uniqueClientId!=null
                            "
                            >Please select a Trailer/Truck/Container</span
                            >
                            <span
                            class="text-danger"
                            *ngIf="
                            isInputTouched && uniqueClientId==null
                            "
                            >Client required</span
                            >
                    </div>
                    <div class="field col-12 md:col-4">
                        <label htmlFor="trailerTrucks">Trailer/Container Status</label>
                        <p-dropdown [options]="trailerStatus" formControlName="fleetStatus" placeholder="Select Trailer/Container Status" optionLabel="name" optionValue="code"></p-dropdown>
                    </div>
                    <div class="field col-12 md:col-4">
                        <label htmlFor="drivers">Drivers/Yard Spotters<span class="text-danger">*</span></label>
                        <p-dropdown [options]="drivers" formControlName="assignedToUserId" optionLabel="fullName" placeholder="Select Drivers/Yard Spotters" optionValue="userId" ></p-dropdown>
                        <span
                            class="text-danger"
                            *ngIf="
                            jobForm.controls.assignedToUserId.touched &&
                            jobForm.controls.assignedToUserId.invalid
                            "
                            >Driver/Yard Spotter is required</span
                            >
                    </div>
                    <div class="field col-12">
                        <div class="grid">
                            <div class="col-12">
                                <label htmlFor="jobPriorities">Spot Priorities<span class="text-danger">*</span></label>
                            </div>
                            <div class="col-12 md:col-4">
                                <div class="field-radiobutton mb-0">
                                    <p-radioButton name="priority" value="HIGH" formControlName="priority" id="priority"></p-radioButton>
                                    <p-tag rounded="true" severity="danger" value="High"></p-tag>
                                </div>
                            </div>
                            <div class="col-12 md:col-4">
                                <div class="field-radiobutton">
                                    <p-radioButton name="priority" value="MEDIUM" formControlName="priority"
                                                id="priority2"></p-radioButton>
                                    <p-tag rounded="true" severity="primary" value="Medium"></p-tag>
                                </div>
                            </div>
                            <div class="col-12 md:col-4">
                                <div class="field-radiobutton">
                                    <p-radioButton name="priority" value="LOW" formControlName="priority" id="priority3"></p-radioButton>
                                    <p-tag rounded="true" severity="success" value="Low"></p-tag>
                                </div>
                            </div>
                            <span
                            class="text-danger"
                            *ngIf="
                            jobForm.controls.priority.touched &&
                            jobForm.controls.priority.invalid
                            "
                            >Priority is required</span
                            >
                        </div>
                    </div>
                    <div class="field col-12">
                        <button style="width: fit-content !important;" pButton class="p-button-primary mx-2" type="button" (click)="onSubmit()" label="Submit"></button>
                        <button style="width: fit-content !important;" *ngIf="jobId && hideButtonsIfSpotter === false" pButton class="p-button-danger mx-2" type="button" (click)="deleteJob()" label="Delete"></button>
                    </div>
                </div>
            </form>
		</div>
	</div>
</div>

<p-dialog [(visible)]="deleteJobDialog" header="Confirm" [modal]="true" [style]="{width:'450px'}">
	<div class="flex align-items-center justify-content-start">
		<i class="pi pi-exclamation-triangle mr-3 my-4" style="font-size: 2rem"></i>
		<span *ngIf="jobId">Are you sure you want to delete this spot ?</span>
	</div>
	<ng-template pTemplate="footer">
		<button pButton pRipple icon="pi pi-times" class="p-button-text" label="No" (click)="deleteJobDialog = false"></button>
		<button pButton pRipple icon="pi pi-check" class="p-button-text" label="Yes" (click)="confirmDelete()"></button>
	</ng-template>
</p-dialog>
