import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from 'src/app/constants/app.constants';
import { IpagedQuery } from 'src/app/model/IpagedQuery';

@Injectable({
  providedIn: 'root'
})
export class ManageLocationsService {

  constructor(private http: HttpClient) { }

  viewLocations(query:IpagedQuery,clientId): Observable<any> {
    const params = new HttpParams({
      fromObject:{
        isActive:query.isActive.valueOf(),
        page:query.page.toString(),
        size:query.size.toString(),
      }
    })
    return this.http.get(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/clients/${clientId}/locations`,{params});
  }

  createEntryExit(locationId,clientId,obj):Observable<any>{
    return this.http.post(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/clients/${clientId}/locations/${locationId}/fleetEntryExit`,obj);
  }

  deleteLocation(clientId,locationId): Observable<any> {
    return this.http.delete(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/clients/${clientId}/locations/${locationId}`);
  }

  makeDefault(clientId,locationId): Observable<any> {
    return this.http.patch(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/clients/${clientId}/locations/${locationId}/makeDefault`,null);
  }
}
