import { Component, ErrorHandler, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AppAlertService } from 'src/app/app-alert/service/app-alert.service';
import { AppLoaderService } from 'src/app/app-loader/service/app-loader.service';
import { ErrorService } from 'src/app/error-handling/error.service';
import { IpagedQuery } from 'src/app/model/IpagedQuery';
import { ManageClientsService } from '../manage-clients/manage-clients.service';
import { ManageUsersService } from '../manage-users/manage-users.service';
import { AddUserService } from './add-user.service';
import { TokenService } from 'src/app/security/token.service';
import { APP_CONSTANTS } from 'src/app/constants/app.constants';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

  userForm: FormGroup;
  breadcrumbItems: MenuItem[];
  query:IpagedQuery;
  clientList;
  roleList = [
        { name: 'Admin', code: 'a2c227ae-a610-4ba8-88c7-2ae61f9fd9c4' },
        { name: 'Client', code: 'f1023da7-98f3-43c0-abbf-c9f07c90f4fe' },
        { name: 'Supervisor', code: 'ace1b5c7-a19f-4857-be94-aa68de1c2142' },
        { name: 'Driver ', code: 'b09614be-3973-4f89-b237-ff3506ccdd70' },
        { name: 'Guard', code: '20a38eb5-16f9-4d50-8c98-1d4ecbb3f333' },
        { name: 'Yard Spotter', code: '4c8d3ec7-2dc6-46c3-afb0-c266a3448f26' },
        { name: 'IT', code: '095f6cc9-2a6f-4963-9de0-f52441b45330' }
  ];
  timeZoneList = [
    {zone: 'America/Adak'},
    {zone: 'America/Anchorage'},
    {zone: 'America/Boise'},
    {zone:'America/Chicago'},
    {zone: 'America/Denver'},
    {zone: 'America/Detroit'},
    {zone: 'America/Fort_Wayne'},
    {zone: 'America/Indiana/Indianapolis'},
    {zone: 'America/Indiana/Knox'},
    {zone: 'America/Indiana/Marengo'},
    {zone: 'America/Indiana/Petersburg'},
    {zone: 'America/Indiana/Tell_City'},
    {zone: 'America/Indiana/Vevay'},
    {zone: 'America/Indiana/Vincennes'},
    {zone: 'America/Indiana/Winamac'},
    {zone: 'America/Indianapolis'},
    {zone: 'America/Juneau'},
    {zone: 'America/Kentucky/Louisville'},
    {zone: 'America/Kentucky/Monticello'},
    {zone: 'America/Knox_IN'},
    {zone: 'America/Los_Angeles'},
    {zone: 'America/Louisville'},
    {zone: 'America/Menominee'},
    {zone:  'America/Metlakatla'},
    {zone:  'America/New_York'},
    {zone:  'America/Nome'},
    {zone:  'America/North_Dakota/Beulah'},
    {zone:  'America/North_Dakota/Center'},
    {zone:   'America/North_Dakota/New_Salem'},
    {zone:  'America/Phoenix'},
    {zone:  'America/Shiprock'},
    {zone:  'America/Sitka'},
    {zone:  'America/Yakutat'},
    {zone:  'Navajo'},
    {zone:  'Pacific/Honolulu'},
    {zone:  'Pacific/Johnston'},
    {zone:  'US/Alaska'},
    {zone:  'US/Aleutian'},
    {zone:  'US/Arizona'},
    {zone:  'US/Central'},
    {zone:  'US/East-Indiana'},
    {zone:  'US/Eastern'},
    {zone:  'US/Hawaii'},
    {zone:  'US/Indiana-Starke'},
    {zone:  'US/Michigan'},
    {zone:  'US/Mountain'},
    {zone:  'US/Pacific'}
   ];  
  userId: any;
  deleteUserDialog: boolean = false;
  passwordDialog: boolean = false;
  subscription = new Subscription();
  password = "Haroon";
  email:String;
  confirmResetPasswordDialog = false;
  resetPasswordModal = false;
  hideClient: boolean;
  userRoles: any;

    

  constructor(private fb:FormBuilder,
    private router:Router,
    private manageClientsService:ManageClientsService,
    private loader:AppLoaderService,
    private alertService:AppAlertService,
    private addUserService:AddUserService,
    private activatedRoute:ActivatedRoute,
    private manageUsersService : ManageUsersService,
    private tokenService:TokenService,
    private errorService:ErrorService) {
    this.userForm = this.fb.group({
      clientIds: [''],
      email: ['',[Validators.required,Validators.email]],
      firstName: ['',Validators.required],
      lastName: ['',Validators.required],
      phone: [''],
      roleIds: ['',Validators.required],
      timeZone: [''],
      roleId:['',Validators.required]
    });

    this.userForm.get('roleId').valueChanges.subscribe(val => {
      if (val === "a2c227ae-a610-4ba8-88c7-2ae61f9fd9c4" || val === "095f6cc9-2a6f-4963-9de0-f52441b45330") {
        this.userForm.controls['clientIds'].clearValidators(); 
      } else {
        this.userForm.controls['clientIds'].setValidators([Validators.required]);
      }
      this.userForm.controls['clientIds'].updateValueAndValidity();
      if (val !== "a2c227ae-a610-4ba8-88c7-2ae61f9fd9c4" && val !== "095f6cc9-2a6f-4963-9de0-f52441b45330") {
        this.userForm.controls['timeZone'].clearValidators(); 
      } else {
        this.userForm.controls['timeZone'].setValidators([Validators.required]);
      }
      this.userForm.controls['timeZone'].updateValueAndValidity();
    });

    this.activatedRoute.queryParams.subscribe(qparams=>{
      if(qparams["userId"]){
        this.userId = qparams["userId"];
      }
    })
  }

  ngOnInit(): void {
    this.userRoles = this.tokenService.getUserRoles();
    if (this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_SUPERVISOR)){
      this.roleList = this.roleList.filter(role =>
        ['Driver ', 'Guard', 'Yard Spotter'].includes(role.name)
      );
    }
    console.log(this.roleList);
    this.breadcrumbItems = [];
      this.breadcrumbItems.push({ label: 'Users',routerLink:'../manage-users'});
      this.breadcrumbItems.push({ label: 'Create User'});

    // this.userForm.patchValue({
    //   timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone ? Intl.DateTimeFormat().resolvedOptions().timeZone : 'US/Eastern'
    //     });
    
    this.query = {isActive:true,size:100,page:0}
    this.viewClients(this.query);

    if(this.userId){
      this.getUserById(this.userId);
    }
  }

  getUserById(userId){
    this.loader.show();
    this.addUserService.getUserById(userId).subscribe(res=>{
      this.userForm.patchValue(res);
      this.patchValuesForClients(res);
      this.patchValuesForRoles(res);
      this.loader.hide();
    })
  }

  patchValuesForRoles(res: any) {
    let roleIds = []
    if(res.roles){
      res.roles.map(el=>{
        roleIds.push(el.roleId);
      });
      this.userForm.patchValue({
        roleIds,
        roleId:roleIds[0]
      });
      this.toggleClient();
    }else{
      this.userForm.patchValue({
        roleIds : [],
        roleId:''
      })
    }
    
  }

  patchValuesForClients(res: any) {
    let clientIds = []
    if(res.clients){
      res.clients.map(el=>{
        clientIds.push(el.clientId);
      });
      this.userForm.patchValue({
        clientIds
      })
    }else{
      this.userForm.patchValue({
        clientIds:[]
      })
    }
    
  }

  viewClients(query:IpagedQuery){
    this.loader.show();
    this.manageClientsService.viewClients(query).subscribe(response=>{
      this.clientList = response.list;
      this.loader.hide();
    },(error) => {
      this.loader.hide();
      this.errorService.handleError(error, true);
    })
  }

  onSubmit(){
    this.userForm.patchValue({
      roleIds:[this.userForm.value.roleId]
    })
    if(this.userForm.invalid){
      this.userForm.markAllAsTouched();
    }
    else{
      this.loader.show();
      if(this.userId){
        this.addUserService.updateUser(this.userId,this.userForm.value).subscribe(res=>{
          this.loader.hide();
          this.alertService.alertSuccess(['User Updated Successfully']);
          this.router.navigate(['main/manage-users']) 
        },(error) => {
          this.loader.hide();
          this.errorService.handleError(error, true);
      })
      }else{
        this.addUserService.saveUser(this.userForm.value).subscribe(res=>{
          this.loader.hide();
          this.alertService.alertSuccess(['User Added Successfully']);
          this.password = res.password;
          this.email = res.email; 
          this.passwordDialog = true;
        },error=>{
          this.errorService.handleError(error,true);
        })
      }
    }
  }


  

  deleteUser() {
    this.deleteUserDialog = true;
  }
 
  confirmresetPassword(userId : any){
    this.loader.show();
    this.manageUsersService.resetpassword(userId).subscribe(res=>{
    this.loader.hide();
    this.alertService.alertSuccess(['password reset successfully']);
    this.password = res.password;
    this.resetPasswordModal = true;
  },(error) => {
    this.loader.hide();
    this.errorService.handleError(error, true);
  })
}


  resetPassword(){
  this.loader.show();
  this.manageUsersService.resetpassword(this.userId).subscribe(res=>{
  this.loader.hide();
  this.alertService.alertSuccess(['password reset successfully']);
  this.password = res.password;
  this.resetPasswordModal = true;
  },(error) => {
    this.loader.hide();
    this.errorService.handleError(error, true);
})
  }

  confirmDelete(){
    this.loader.show();
    this.subscription.add(
    this.addUserService.deleteUser(this.userId).subscribe(res=>{
        this.deleteUserDialog = false;
        this.alertService.alertSuccess(['User Deactivated Successfully']);
        this.router.navigate(['main/manage-users'])
        this.loader.hide();
    },(error) => {
      this.loader.hide();
      this.errorService.handleError(error, true);
  })
    )
  }

  routeToManageUsers(){
    this.passwordDialog = false;
    this.router.navigate(['main/manage-users'])
  }

  toggleClient(){
    if(this.userForm.value.roleId === 'a2c227ae-a610-4ba8-88c7-2ae61f9fd9c4' || this.userForm.value.roleId === '095f6cc9-2a6f-4963-9de0-f52441b45330'){
      this.hideClient = true;
      this.userForm.patchValue({
        clientIds:null
      })
    }else{
      this.hideClient = false;
    }
  }

}
