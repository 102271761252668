import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from 'src/app/constants/app.constants';
import { IpagedQuery } from 'src/app/model/IpagedQuery';

@Injectable({
  providedIn: 'root'
})
export class ManageSpotsService {

  constructor(private http: HttpClient) { }

  viewSpots(query:IpagedQuery,clientId,locationId,status?:any,fleetStatus?:any): Observable<any> {
    let params = new HttpParams({
      fromObject:{
        //'isActive':query.isActive.valueOf(),  //removing this will list all spots (active & deactive)
        'page':query.page.toString(),
        'size':query.size.toString(),
        'location.uuid':locationId.toString(),
        'sort':'spotName'
      }
    })
    if(status){
      params = params.append("status",status.toString())
    }
    if(fleetStatus){
      params = params.append("fleet.fleetStatus",fleetStatus.toString())
    }
    return this.http.get(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/clients/${clientId}/spots`,{params});
  }

  viewDropdownSpots(query:IpagedQuery,clientId,locationId,status?:any,fleetStatus?:any): Observable<any> {
    let params = new HttpParams({
      fromObject:{
        'isActive':query.isActive.valueOf(),
        'page':query.page.toString(),
        'size':query.size.toString(),
        'location.uuid':locationId.toString(),
        'sort':'spotName'
      }
    })
    if(status){
      params = params.append("status",status.toString())
    }
    if(fleetStatus){
      params = params.append("fleet.fleetStatus",fleetStatus.toString())
    }
    return this.http.get(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/clients/${clientId}/spots/dropdown`,{params});
  }


  saveSpot(clientId,spotObj): Observable<any> {
    return this.http.post(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/clients/${clientId}/spots`,spotObj);
  }

  updateSpot(clientId,spotId,spotObj): Observable<any> {
    return this.http.put(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/clients/${clientId}/spots/${spotId}`,spotObj);
  }

  deleteSpot(clientId,spotId): Observable<any> {
    return this.http.delete(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/clients/${clientId}/spots/${spotId}`);
  }
  
  activateSpot(clientId,spotId): Observable<any> {
    return this.http.put(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/clients/${clientId}/spots/${spotId}/activate`,"");
  }
}
