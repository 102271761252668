import { Component, OnInit } from '@angular/core';
import { Product } from '../../api/product';
import { ProductService } from '../../service/productservice';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { concatMap, map, Subscription } from 'rxjs';
import { ManageLocationsService } from '../manage-locations/manage-locations.service';
import { ManageMessagesService } from './manage-messages.service';
import { IpagedQuery } from 'src/app/model/IpagedQuery';
import { ManageClientsService } from '../manage-clients/manage-clients.service';
import { ManageSpotsService } from '../manage-spots/manage-spots.service';
import { AppLoaderService } from 'src/app/app-loader/service/app-loader.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ManageUsersService } from '../manage-users/manage-users.service';
import { TokenService } from 'src/app/security/token.service';
import { AppAlertService } from 'src/app/app-alert/service/app-alert.service';
import { ManageFleetsService } from '../manage-fleets/manage-fleets.service';
import { APP_CONSTANTS } from 'src/app/constants/app.constants';
import { ErrorService } from 'src/app/error-handling/error.service';
import { AddFleetService } from '../add-fleet/add-fleet.service';
import { WebSocketService } from 'src/app/web-socket.service';
import { DashboardService } from '../dashboard/dashboard.service';
import * as moment from 'moment';

@Component({
  selector: 'app-manage-messages',
  templateUrl: './manage-messages.component.html',
  providers: [MessageService, ConfirmationService],
  styleUrls: ['../../../assets/demo/badges.scss','./manage-messages.component.scss']
})
export class ManageMessagesComponent implements OnInit {

  breadcrumbItems: MenuItem[];

  productDialog: boolean;

  readDialog: boolean = false;

  supervisor = "Supervisor";

  subscription = new Subscription();

  // messages : any;


    deleteProductsDialog: boolean = false;

    messages = [
      // {
      //     id:"1",
      //     from: 'admin@maildrop.cc',
      //     to: 'dynacraft@maildrop.cc',
      //     message: 'DD14-This trailer has racks and needs to be moved to the left slightly',
      //     date: '01/01/2022'
      // },
      // {
      //   id:"2",
      //   from: 'admin@maildrop.cc',
      //   to: 'pegasus@maildrop.cc',
      //   message: 'DD14-This trailer has racks and needs to be moved to the left slightly',
      //   date: '01/01/2022'
      // },
      // {
      //   id:"3",
      //   from: 'admin@maildrop.cc',
      //   to: 'dynacraft@maildrop.cc',
      //   message: 'DD14-This trailer has racks and needs to be moved to the left slightly',
      //   date: '01/01/2022'
      // },
      // {
      //   id:"4",
      //   from: 'admin@maildrop.cc',
      //   to: 'dynacraft@maildrop.cc',
      //   message: 'DD14-This trailer has racks and needs to be moved to the left slightly',
      //   date: '01/01/2022'
      // }
  ];

    product: Product;

    selectedProducts: Product[];

    submitted: boolean;

    cols: any[];

    statuses: any[];

    type: any[];

    query:IpagedQuery;

    clients: any[];

    actualMessages = [];
  
    

    rowsPerPageOptions = [5, 10, 20];

    modalTitle;
  modalMessage: any;
  messageType: { name: string; code: string; }[];
  clientLocations: { name: string; value: string; }[];
  clientLocations2: { name: string; value: string; }[];
  loading: boolean;
  totalRecords: any;
  locationList: any;
  spotList: any;
  drpQuery: { isActive: boolean; size: number; page: number; };
  clientId: any;
  locationId: any;
  messageForm: FormGroup;
  pickupSpotList: any;
  dropSpotList: any;
  userList;
  loggedInUserId;
  messageFormDialog = false;
  fleetList: any[];
  userRoles: any;
  userType: string;
  fleetForm: FormGroup;
  messageStatus: { name: string; code: string; }[];
  filterByReadAndUnread: any = null;
  inputOutbox = "INBOX";
  showHotTrailerNumberField: boolean;
  private webSocket: WebSocket;
  todayQuery:IpagedQuery;
  messageStatistics = {
    newCount:0,
    readCount:0
}
filteredFleets: any;
unreadMessage:boolean = false;
notAdminIT: boolean = false;

    constructor(private router:Router,
                private manageMessagesService : ManageMessagesService,
                private manageClientsService : ManageClientsService,
                private manageLocationsService : ManageLocationsService,
                private manageSpotsService : ManageSpotsService,
                private manageFleetsService : ManageFleetsService,
                private loader:AppLoaderService,
                private fb:FormBuilder,
                private manageUsersService:ManageUsersService,
                private tokenService:TokenService,
                private alertService:AppAlertService,
                private errorService:ErrorService,
                private addFleetService:AddFleetService,
                private webSocketService:WebSocketService,
                private dashboardService:DashboardService) {

                  this.webSocket = new WebSocket(APP_CONSTANTS.SOCKET_URL);
                  this.webSocket.onmessage = (event) => {
                      
                    this.getMessageStatics();
                    this.getMessages(this.query,null,this.inputOutbox);
                      console.log("messages");
                      
                     
                  };

                  this.messageForm = this.fb.group({
                    dropLocationId: [''],
                    dropSpotId: [''],
                    messageBody: ['',Validators.required],
                    pickupLocationId: [''],
                    pickupSpotId: [''],
                    toUserIds: [''],
                    type: ['',Validators.required],
                    clientLocationId: [''],
                    fleetId:[''],
                    carrier:[''],
                    priority:['',Validators.required],
                    unitNumber: [''],
                    trailer:[''],
                    clients:['']
                  });

                  this.fleetForm = this.fb.group({
                    carrier: [''],
                    unitNumber: [''],
                    isHotTrailer: [true],
                    type:['TRAILER'],
                    clientIds:['']
                  })

                  this.messageForm.get('type').valueChanges.subscribe(val => {
                    if (this.messageForm.get('type').value == 'HOT_TRAILER') {
                      this.messageForm.controls['unitNumber'].setValidators([Validators.required]);
                    } else {
                      this.messageForm.controls['unitNumber'].clearValidators();
                    }
                    this.messageForm.controls['unitNumber'].updateValueAndValidity();

                    if (this.messageForm.get('type').value == 'TRAILER_TRUCK_MOVE') {
                      this.messageForm.controls['trailer'].setValidators([Validators.required]);
                    } else {
                      this.messageForm.controls['trailer'].clearValidators();
                    }
                    this.messageForm.controls['trailer'].updateValueAndValidity();
                  });
                }

    ngOnInit() {

      this.userRoles = this.tokenService.getUserRoles();
      if (this.userRoles.some(role => role !== APP_CONSTANTS.USER_ROLES.ROLE_ADMIN && role !== APP_CONSTANTS.USER_ROLES.ROLE_IT)) {
        this.notAdminIT = true;
      }

      let nowTime =  moment().format('yyyy-MM-DD HH:mm');
      let twentyFourHours =  moment().add(-24,'hours').format('yyyy-MM-DD HH:mm');
      this.todayQuery = {
        fromDateTime:twentyFourHours,
        toDateTime:nowTime
      }
      this.query = {isActive:true,size:10,page:0};
      this.drpQuery = {isActive:true,size:1000,page:0};
      this.viewClients(this.drpQuery);
      this.viewUsers(this.drpQuery)
      this.getMessages(this.query,null,this.inputOutbox);
      this.loggedInUserId = this.tokenService.getUserId();
      
      if (this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_CLIENT && role !== APP_CONSTANTS.USER_ROLES.ROLE_ADMIN && role !== APP_CONSTANTS.USER_ROLES.ROLE_IT)){
        this.userType = APP_CONSTANTS.USER_ROLES.ROLE_CLIENT;
        this.clientId = this.tokenService.getUserClientId();
        this.fleetForm.patchValue({
          clientIds:[this.tokenService.getUserClientId()]
        });
        this.getClientLocations(this.drpQuery, this.tokenService.getUserClientId());
        this.getFleetList(this.drpQuery,this.tokenService.getUserClientId());
      }
      
      
        // this.productService.getProducts().then(data => this.products = data);
      
        this.breadcrumbItems = [];
        this.breadcrumbItems.push({ label: 'Messages'});

        // this.clients = [
        //   {name: 'Supervisor', value: 'Pegasus'},
        //   {name: 'Blair Enterprises', value: 'Dynacraft'},
        // ];

        // this.clientLocations = [
        //   {name: 'Location 1', value: '1'},
        //   {name: 'Location 2', value: '2'},
        //   {name: 'Location 2', value: '3'},
        //   {name: 'Location 2', value: '4'}
        // ];

        // this.clientLocations2 = [
        //   {name: 'Location 1', value: '1'},
        //   {name: 'Location 2', value: '2'},
        //   {name: 'Location 2', value: '3'},
        //   {name: 'Location 2', value: '4'}
        // ];

      this.messageType = [
        { name: 'Trailer/Container Move', code: 'TRAILER_TRUCK_MOVE' },
        { name: 'Hot Trailer', code: 'HOT_TRAILER' },
        { name: 'Information', code: 'INFORMATION' }
      ];

      this.messageStatus = [
        { name: 'Read', code: 'READ' },
        { name: 'Unread', code: 'NEW' }
      ]
    }
    
    getMessageStatics()
    {
      this.dashboardService.getMessagesCount(this.todayQuery).subscribe(response=>{
             this.messageStatistics = response;
             if(this.messageStatistics.newCount != 0)
             {
                this.unreadMessage= true;
             }
             
             this.dashboardService.sendMessageCount(this.messageStatistics.newCount);
        }
      )
    }
    
    viewClients(query:IpagedQuery){
      this.loader.show();
      this.manageClientsService.viewClients(query).subscribe(response=>{
        this.clients = response.list;
        if (this.notAdminIT) {
          this.messageForm.patchValue({
            clients: this.clients[0].clientId
          });
          
          this.clientId = this.clients[0].clientId;
          this.getClientLocations(this.drpQuery, this.clientId);
          this.getFleetList(this.drpQuery,this.clientId);
          this.fleetForm.patchValue({
            clientIds:[this.clientId]
          })
        }
        this.loader.hide();
      },(error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
    })
  }

  getClientLocations(query,clientId) {
    this.loader.show();
    this.subscription.add(
        this.manageLocationsService.viewLocations(query,clientId).subscribe(response=>{
            this.locationList = response.list;
            this.loader.hide();
        },(error) => {
          this.loader.hide();
          this.errorService.handleError(error, true);
      })
    )
}

getClientSpots(query,clientId,locationId,type) {
  this.loader.show();
  this.subscription.add(
      this.manageSpotsService.viewSpots(query,clientId,locationId)
      .pipe(
        map(spots=>{
          let spotsArray = [];
          for(let spot of spots.list){
              let obj = {
                  ...spot,
                  spotAndStatus : this.spotAndFleetStatus(spot)
                };
                spotsArray.push(obj);
          }
          return { 
            list : spotsArray
          }
          
        })
      ).subscribe(response=>{
          type == 'pickup' ? this.pickupSpotList = response.list : this.dropSpotList = response.list;
          this.loader.hide();
      },(error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
    })
  )
}

    getMessages(query,filter?:any,inboxOutbox?:any) {
      this.loading = true;
      this.manageMessagesService.viewMessages(query,filter,inboxOutbox).subscribe(response=>{
        this.messages = response.list;
        this.totalRecords = response.totalElements;
                this.loading = false;
      },(error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
    })
    
  }

  onClientChange(event){
    this.clientId = event.value;
    this.getClientLocations(this.drpQuery, this.clientId);
    this.getFleetList(this.drpQuery,this.clientId);
    this.fleetForm.patchValue({
      clientIds:[this.clientId]
    })
    
    
  }

  onLocationChange(event,type){
    this.locationId = event.value
    console.log(this.locationId);
    this.getClientSpots(this.drpQuery,this.clientId, this.locationId,type)
    
  }

    hideDialog() {
      this.messageForm.reset();
      this.messageFormDialog = false;
      
      this.submitted = false;
    }

    saveProduct() {
      this.submitted = true;
    }

    openNew() {
      this.messageFormDialog = true;
    }

    readMessage(message,status){
      if(message.toUser.userId === this.loggedInUserId){
        if(status == 'changeStatus'){
          this.manageMessagesService.changeMessageStatus(message.messageId,{status:'READ'}).subscribe(res=>{
            this.getMessages(this.query,this.filterByReadAndUnread,this.inputOutbox);
          },(error) => {
            this.loader.hide();
            this.errorService.handleError(error, true);
        })
        }
      }
      this.modalMessage = message;
      this.readDialog = true;
      
    }

    hideMessage() {
      this.readDialog = false;
      
    }


    viewUsers(query:IpagedQuery){
      this.loader.show();
      this.subscription.add(
        this.manageUsersService.viewUsers(query).
        pipe(
          map(res=>{
            let users = [];
            for(let user of res.list){
              let obj = {
                ...user,
                fullName : user.firstName+" "+user.lastName
              };
              users.push(obj);
            }
            return users
          })
        ).subscribe(users=>{
          // let usersList=[];
          // users.map(user=>{
          //   for(let role of user["roles"]){
          //     if(role.roleId == 'b09614be-3973-4f89-b237-ff3506ccdd70' || role.roleId == '4c8d3ec7-2dc6-46c3-afb0-c266a3448f26'){
          //       usersList.push(user);
          //     }
          //   }
          // })
          // this.userList = usersList;
          this.userList = users.filter(user=> user.userId !== this.loggedInUserId);
          if(this.userType == APP_CONSTANTS.USER_ROLES.ROLE_CLIENT){
            this.userList = this.userList.filter(user=>{
              for(let role of user.roles){
                if(role.roleName == "SUPERVISOR"){
                  return user;
                }
              }
            });

            this.getClientLocations(this.drpQuery, this.tokenService.getUserClientId());
            this.getFleetList(this.drpQuery,this.tokenService.getUserClientId());
          }
          this.loader.hide();
        },(error) => {
          this.loader.hide();
          this.errorService.handleError(error, true);
        })
      )
    }

    getFleetList(query: IpagedQuery,clientId?: any) {
      this.loader.show();
      this.subscription.add(
        this.manageFleetsService.viewFleets(query,clientId)
        .pipe(
          map(res=>{
            let fleets = [];
            for(let fleet of res.list){
              let obj = {
                ...fleet,
                plateNumber_unitNumber : fleet.plateNumber+"-"+`(${fleet.unitNumber})`
              };
              fleets.push(obj);
            }
            return { 
              list : fleets
            }
          })
        )
        .subscribe(response=>{
          this.fleetList = response.list;
          this.fleetList = this.fleetList.filter(fleet=> fleet.isHotTrailer === true);
          this.loader.hide();
        },(error) => {
          this.loader.hide();
          this.errorService.handleError(error, true);
        })
      ) 
    }

    onFleetChange(event){
      let fleet = this.fleetList.find(el=> el.fleetId === event.value);
      this.messageForm.patchValue({
        carrier: fleet.carrier,
        unitNumber: fleet.unitNumber
      })
    }


  sendMessage(){
    this.submitted = true;
    console.log(this.messageForm);
    
    if(this.messageForm.invalid){
      this.messageForm.markAllAsTouched();
    }else{
      if(this.messageForm.get('type').value == 'HOT_TRAILER'){
        this.saveHotTrailer();
      }else{
        this.loader.show();
        this.manageMessagesService.sendMessage(this.messageForm.value).subscribe(res=>{
        this.loader.hide();
        this.alertService.alertSuccess(['Message sent successfully']);
        this.getMessages(this.query,null,this.inputOutbox);
        this.messageFormDialog = false;
        this.messageForm.reset();
        this.submitted = false;
      },(error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
      })
        
      }
    }
  }

  paginate(event){
    this.query.page = event.page;
    this.getMessages(this.query,this.filterByReadAndUnread,this.inputOutbox);
   
  }
  

  saveHotTrailer(){

      if(this.showHotTrailerNumberField == true){
        this.messageForm.patchValue({
          fleetId : null
        })
      }

      if(this.messageForm.value.fleetId == null){
        this.loader.show();
        this.fleetForm.patchValue({
          carrier: this.messageForm.get('carrier').value,
          unitNumber: this.messageForm.get('unitNumber').value
        });
        this.addFleetService.saveFleet(this.fleetForm.value)
        .pipe(
          concatMap(fleet=> {
            this.messageForm.patchValue({
              fleetId : fleet.fleetId
            })
            return this.manageMessagesService.sendMessage(this.messageForm.value)
          })
        )
        .subscribe(res=>{
          this.loader.hide();
          this.alertService.alertSuccess(['Message sent successfully']);
          this.getMessages(this.query,null,this.inputOutbox);
          this.messageFormDialog = false;
          this.messageForm.reset();
          this.submitted = false;
        },(error) => {
          this.loader.hide();
          this.errorService.handleError(error, true);
        })
      }else{
          this.loader.show();
          this.manageMessagesService.sendMessage(this.messageForm.value).subscribe(res=>{
          this.loader.hide();
          this.alertService.alertSuccess(['Message sent successfully']);
          this.getMessages(this.query,null,this.inputOutbox);
          this.messageFormDialog = false;
          this.messageForm.reset();
          this.submitted = false;
        },(error) => {
          this.loader.hide();
          this.errorService.handleError(error, true);
        })
      }   
  }

  filterMessagesByReadAndUnread(event){
        this.filterByReadAndUnread = event.value;
   
      this.getMessages(this.query,this.filterByReadAndUnread,this.inputOutbox);
      }

  onTypeChange(event){
    this.messageForm.reset();
    this.messageForm.patchValue({
      type:event.value
    })
    this.viewClients(this.drpQuery);
  }

  handleTabChange(event){
    if(event.index==0){
      this.inputOutbox = "INBOX";
      this.getMessages(this.query,null,this.inputOutbox)
    }else{
      this.inputOutbox = "OUTBOX";
      this.getMessages(this.query,null,this.inputOutbox)
    }
  }

  spotAndFleetStatus(spot){
    if(spot.status == 'EMPTY' && spot?.fleet == null){
      return `${spot.spotName} - Empty`
    }else if(spot.status == 'OCCUPIED' && spot?.fleet?.fleetStatus == 'FULL'){
      return `${spot.spotName} - ${spot?.fleet?.unitNumber} - Occupied Full Trailer`
    }else if(spot.status == 'OCCUPIED' && spot?.fleet?.fleetStatus == 'EMPTY'){
      return `${spot.spotName} - ${spot?.fleet?.unitNumber} - Occupied Empty Trailer`
    }else if(spot.status == 'TO_BE_EMPTY'){
      return spot?.fleet == null ? `${spot.spotName} - Scheduled for Pick-Up` : `${spot.spotName} - ${spot?.fleet?.unitNumber} - Scheduled for Pick-Up`
    }else if(spot.status == 'TO_BE_OCCUPIED'){
      return spot?.fleet == null ? `${spot.spotName} - Location Reserved` : `${spot.spotName} - ${spot?.fleet?.unitNumber} - Location Reserved` 
    }else{
      return `${spot.spotName}`
    }
   
  }

  showTrailerFields(){
    if(this.showHotTrailerNumberField == true){
      this.showHotTrailerNumberField = false;
    }else{
      this.showHotTrailerNumberField = true;
    }
   
  }

  checkIfHotTrailer(fleet) {
    if (fleet.isHotTrailer) {
      return `${fleet.unitNumber} - (Hot Trailer)`
    } else {
      return `${fleet.unitNumber}`
    }
  }

  filterFleets(event) {
 
      this.manageFleetsService.viewFleets(this.query, this.clientId, event.query)
        .pipe(
          map(fleets => {
            let fleetsArray = [];
            for (let fleet of fleets.list) {
              let obj = {
                ...fleet,
                trailer: this.checkIfHotTrailer(fleet)
              };
              fleetsArray.push(obj);
            }
            return {
              list: fleetsArray
            }

          })
        ).subscribe(response => {
          this.filteredFleets = response.list;
          this.loader.hide();
        }, (error) => {
          this.loader.hide();
          this.errorService.handleError(error, true);
        });

    

  }

  onFleetSelect(event) {
    this.messageForm.patchValue({
      fleetId: event.fleetId,
      trailer: event.trailer
    });
  }

  onUnitNumberChange(event){
    console.log(event)
    this.messageForm.patchValue({
      fleetId: "",
    })
    // this.uniqueFleetId = "";
  }


}
