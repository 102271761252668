<div class="grid">
	<div class="col-12">
        <div class="card card-w-title">
            <p-breadcrumb [model]="breadcrumbItems" [home]="{icon: 'pi pi-home',routerLink:'../'}"></p-breadcrumb>
        </div>
    </div>
	<div class="col-12">
		<div class="card">
			<h5>Add Asset</h5>
			<form [formGroup]="fleetForm">
				<div class="p-fluid p-formgrid grid">
					<div class="field col-12 md:col-12">
                        <label htmlFor="client">Select Client</label>
						<!-- <p-dropdown placeholder="Select Client" formControlName="clientId" [options]="clientList" (onChange)="onClientSelect($event)" optionLabel="clientName" optionValue="clientId"></p-dropdown> -->
                        <p-multiSelect [options]="clientList" placeholder="Select Client" formControlName="clientIds" (onPanelHide)="afterClientSelect()" optionLabel="clientName" optionValue="clientId"></p-multiSelect>
                    </div>
					<div class="field col-12 md:col-4">
                        <label htmlFor="location">Location</label>
                        <p-dropdown [options]="clientsLocationsList" formControlName="locationId" placeholder="Select Location" optionLabel="locationName" (onChange)="onLocationChange($event)" optionValue="locationId"></p-dropdown>
                    </div>
					<div class="field col-12 md:col-4">
                        <label htmlFor="spot">Dock/Parking Spot</label>
                        <p-dropdown [options]="spotsList" formControlName="spotId" placeholder="Select Spot" optionLabel="spotAndStatus" optionValue="spotId"></p-dropdown>
                    </div>
					<div class="field col-12 md:col-4">
                        <label htmlFor="trailerTrucks">Trailer/Container Status</label>
                        <p-dropdown [options]="trailerStatus" formControlName="fleetStatus" placeholder="Select Trailer/Container Status" optionLabel="name" optionValue="code"></p-dropdown>
                    </div>
					<div class="field col-12 md:col-6">
						<label htmlFor="type">Type<span class="text-danger">*</span></label>
						<p-dropdown [options]="dropdownItems" formControlName="type" placeholder="Select Type" optionLabel="name" optionValue="code"></p-dropdown>
						<span
                            class="text-danger"
                            *ngIf="
								fleetForm.controls.type.touched &&
								fleetForm.controls.type.invalid
                            "
                            >Type is required</span
                        >
					</div>
					<div class="field col-12 md:col-6">
						<label htmlFor="">Unit Number<span class="text-danger">*</span></label>
						<input pInputText id="unitNumber" formControlName="unitNumber" type="text" />
						<span
                            class="text-danger"
                            *ngIf="
								fleetForm.controls.unitNumber.touched &&
								fleetForm.controls.unitNumber.invalid
                            "
                            >Unit Number is required</span
                        >
					</div>
					<div class="field col-12 md:col-6">
						<!-- <label htmlFor="">Carrier<span class="text-danger">*</span></label>
						<input pInputText id="carrier" formControlName="carrier" type="text" />
						<span
                            class="text-danger"
                            *ngIf="
								fleetForm.controls.carrier.touched &&
								fleetForm.controls.carrier.invalid
                            "
                            >Carrier is required</span
                        > -->
						<label htmlFor="carrier">Carrier<span class="text-danger">*</span></label>
						<p-autoComplete formControlName="carrier" (completeMethod)="getCarrier($event)" [suggestions]="uniqueCarrierList" dataKey="carrier" [dropdown]="true" (onClear)="clearCarrier()" >
                            <ng-template>
                                <div>{{uniqueCarrierList}}</div>
                            </ng-template>
                        </p-autoComplete>
						<span
                            class="text-danger"
                            *ngIf="
                            fleetForm.controls.carrier.touched &&
                            fleetForm.controls.carrier.invalid 
                            ">Carrier is required
							</span>
                           
					</div>
					<!-- <div class="field col-12 md:col-6">
						<label htmlFor="">Owner<span class="text-danger">*</span></label>
						<input pInputText id="owner" formControlName="owner" type="text"/>
						<span
                            class="text-danger"
                            *ngIf="
								fleetForm.controls.owner.touched &&
								fleetForm.controls.owner.invalid
                            "
                            >Owner is required</span
                        >
					</div> -->
					<!-- <div class="field col-12 md:col-6">
						<label htmlFor="year">Year</label>
						<input pInputText id="year" formControlName="year" type="text" />
						<span
                            class="text-danger"
                            *ngIf="
								fleetForm.controls.year.touched &&
								fleetForm.controls.year.invalid
                            "
                            >Year is required</span
                        >
					</div>
					<div class="field col-12 md:col-6">
						<label htmlFor="make">Make</label>
						<input pInputText id="make" formControlName="make" type="text" />
						<span
                            class="text-danger"
                            *ngIf="
								fleetForm.controls.make.touched &&
								fleetForm.controls.make.invalid
                            "
                            >Make is required</span
                        >
					</div>
					<div class="field col-12 md:col-6">
						<label htmlFor="model">Model</label>
						<input pInputText id="model" formControlName="model" type="text" />
						<span
                            class="text-danger"
                            *ngIf="
								fleetForm.controls.model.touched &&
								fleetForm.controls.model.invalid
                            "
                            >Model is required</span
                        >
					</div>
					<div class="field col-12 md:col-6">
						<label htmlFor="color">Color</label>
						<input pInputText id="color" formControlName="color" type="text" />
						<span
                            class="text-danger"
                            *ngIf="
								fleetForm.controls.color.touched &&
								fleetForm.controls.color.invalid
                            "
                            >Color is required</span
                        >
					</div>
					<div class="field col-12 md:col-6">
						<label htmlFor="plateNumber">Plate Number</label>
						<input pInputText id="plateNumber" formControlName="plateNumber" type="text" />
						<span
                            class="text-danger"
                            *ngIf="
								fleetForm.controls.plateNumber.touched &&
								fleetForm.controls.plateNumber.invalid
                            "
                            >Plate Number is required</span
                        >
					</div> -->
					<!-- <div class="field col-12 md:col-6">
						<label htmlFor="assignedTo">Client</label>
						<p-dropdown [options]="assignedList" placeholder="Select Client" optionLabel="name"></p-dropdown>
					</div>
					<div class="field col-12 md:col-6">
						<label htmlFor="companyAssociated">Company Associated</label>
						<p-dropdown [options]="companyAssociated" placeholder="Select Company" optionLabel="name"></p-dropdown>
					</div>
					<div class="field col-12">
						<label htmlFor="dockSpot">Dock/Spot#</label>
						<p-dropdown [options]="dockSpotList" placeholder="Select Dock/Spot" optionLabel="name"></p-dropdown>
					</div>
					<div class="col-12 md:col-4">
						<div class="field-checkbox">
							<p-checkbox name="group1" value="Chicago" id="ch"></p-checkbox>
							<label for="ch">Hot Trailer</label>
						</div>
					</div> -->
					<div class="field col-12">
						<label htmlFor="remarks">Notes</label>
						<textarea pInputTextarea autoResize formControlName="remarks" rows="3" cols="30"></textarea>
					</div>
					<button *ngIf="!fleetId" pButton type="button" (click)="onSubmit()" label="Submit"></button>
					<button style="width: fit-content !important;" *ngIf="fleetId" pButton type="button" (click)="onSubmit()" label="Update"></button>
					<button style="width: fit-content !important;" *ngIf="fleetId" pButton class="p-button-danger mx-2" type="button" (click)="deleteProduct()" label="Delete"></button>
					<button style="width: fit-content !important;" *ngIf="fleetId" pButton class="p-button-secondary mx-2" type="button" (click)="deactivateFleet()" label="Deactivate"></button>
				</div>
			</form>
		</div>
	</div>
</div>

<p-dialog [(visible)]="deleteFleetDialog" header="Confirm" [modal]="true" [style]="{width:'450px'}">
	<div class="flex align-items-center justify-content-start">
		<i class="pi pi-exclamation-triangle mr-3 my-4" style="font-size: 2rem"></i>
		<span *ngIf="fleetId">Are you sure you want to delete this fleet ?</span>
	</div>
	<ng-template pTemplate="footer">
		<button pButton pRipple icon="pi pi-times" class="p-button-text" label="No" (click)="deleteFleetDialog = false"></button>
		<button pButton pRipple icon="pi pi-check" class="p-button-text" label="Yes" (click)="confirmDelete()"></button>
	</ng-template>
</p-dialog>

<p-dialog [(visible)]="deactivateFleetDialog" header="Confirm" [modal]="true" [style]="{width:'450px'}">
	<div class="flex align-items-center justify-content-start">
		<i class="pi pi-exclamation-triangle mr-3 my-4" style="font-size: 2rem"></i>
		<span *ngIf="fleetId">Are you sure you want to deactivate this fleet ?</span>
	</div>
	<ng-template pTemplate="footer">
		<button pButton pRipple icon="pi pi-times" class="p-button-text" label="No" (click)="deactivateFleetDialog = false"></button>
		<button pButton pRipple icon="pi pi-check" class="p-button-text" label="Yes" (click)="confirmDeactivate()"></button>
	</ng-template>
</p-dialog>
