<div class="grid">
    <div class="col-12">
        <div class="card card-w-title">
            <p-breadcrumb [model]="breadcrumbItems" [home]="{icon: 'pi pi-home',routerLink:'../'}"></p-breadcrumb>
        </div>
    </div>
    <div class="col-12">
        <div class="card">
            <p-toast></p-toast>
            <p-toolbar styleClass="mb-4">
                <ng-template pTemplate="left">
                    <div class="my-2">
                        <h5 class="m-0">Messages</h5>
                    </div>
                </ng-template>

                <ng-template pTemplate="right">
                    <button pButton pRipple label="New Message" icon="pi pi-plus" class="p-button-success mr-2" (click)="openNew()"></button>
                </ng-template>
            </p-toolbar>

            <p-tabView (onChange)="handleTabChange($event)">
                <p-tabPanel header="Inbox">
                    <p-table #dt [loading]="loading" [value]="messages" styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" [responsive]="true" [columns]="cols" [rows]="10" [globalFilterFields]="['type']" [rows]="10" [paginator]="false" [rowHover]="true" dataKey="id">
                        <ng-template pTemplate="caption">
                            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                                <span>
                                    <p-dropdown [showClear]="true" placeholder="Filter Read/Unread" [options]="messageStatus" (onChange)="filterMessagesByReadAndUnread($event)" optionLabel="name" optionValue="code"></p-dropdown>
                                </span>
                                <span class="block mt-2 md:mt-0 p-input-icon-left">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th></th>
                                <th pSortableColumn="from">From</th>
                                <th pSortableColumn="to">To</th>
                                <th pSortableColumn="type">Type</th>
                                <th pSortableColumn="date">Date Sent</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-message let-i="rowIndex">
                            <tr>
                                <td>
                                    <button *ngIf="message.status == 'NEW'" pButton pRipple icon="pi pi-envelope" pTooltip="Open" class="p-button-rounded p-button-warning mt-2" (click)="readMessage(message,'changeStatus')"></button>
                                    <button *ngIf="message.status !== 'NEW'" pButton pRipple icon="pi pi-folder-open" pTooltip="Read" class="p-button-rounded p-button-success mt-2" (click)="readMessage(message,'none')"></button>
                                </td>
                                <td>
                                    <span class="p-column-title">From</span>
                                    {{message?.fromUser?.email}}
                                </td>
                                <td>
                                    <span class="p-column-title">To</span>
                                    {{message?.toUser?.email}}
                                </td>
                                <td>
                                    <span class="p-column-title">Type</span>
                                    <p-tag *ngIf="message?.type == 'TRAILER_TRUCK_MOVE'"  rounded="true" severity="danger" value="Trailer Truck Move"></p-tag>
                                    <p-tag *ngIf="message?.type == 'HOT_TRAILER'" rounded="true" severity="primary" value="Hot Trailer"></p-tag>
                                    <p-tag *ngIf="message?.type == 'INFORMATION'" rounded="true" severity="success" value="Information"></p-tag>
                                </td>
                                <td><span class="p-column-title">Date Sent</span>
                                    {{message.audit?.createdDate}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <p-paginator [rows]="10" [showCurrentPageReport] = "true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" [totalRecords]="totalRecords"  (onPageChange)="paginate($event)"></p-paginator>
                </p-tabPanel>
                <p-tabPanel header="Sent">
                    <p-table #dt [loading]="loading" [value]="messages" styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" [responsive]="true" [columns]="cols" [rows]="10" [globalFilterFields]="['type']" [rows]="10" [paginator]="false" [rowHover]="true" dataKey="id">
                        <ng-template pTemplate="caption">
                            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                                <span>
                                    <p-dropdown [showClear]="true" placeholder="Filter Read/Unread" [options]="messageStatus" (onChange)="filterMessagesByReadAndUnread($event)" optionLabel="name" optionValue="code"></p-dropdown>
                                </span>
                                <span class="block mt-2 md:mt-0 p-input-icon-left">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th></th>
                                <th pSortableColumn="from">From</th>
                                <th pSortableColumn="to">To</th>
                                <th pSortableColumn="type">Type</th>
                                <th pSortableColumn="date">Date Sent</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-message let-i="rowIndex">
                            <tr>
                                <td>
                                    <button *ngIf="message.status == 'NEW'" pButton pRipple icon="pi pi-envelope" pTooltip="Open" class="p-button-rounded p-button-warning mt-2" (click)="readMessage(message,'changeStatus')"></button>
                                    <button *ngIf="message.status !== 'NEW'" pButton pRipple icon="pi pi-folder-open" pTooltip="Read" class="p-button-rounded p-button-success mt-2" (click)="readMessage(message,'none')"></button>
                                </td>
                                <td>
                                    <span class="p-column-title">From</span>
                                    {{message?.fromUser?.email}}
                                </td>
                                <td>
                                    <span class="p-column-title">To</span>
                                    {{message?.toUser?.email}}
                                </td>
                                <td>
                                    <span class="p-column-title">Type</span>
                                    <p-tag *ngIf="message?.type == 'TRAILER_TRUCK_MOVE'"  rounded="true" severity="danger" value="Trailer Truck Move"></p-tag>
                                    <p-tag *ngIf="message?.type == 'HOT_TRAILER'" rounded="true" severity="primary" value="Hot Trailer"></p-tag>
                                    <p-tag *ngIf="message?.type == 'INFORMATION'" rounded="true" severity="success" value="Information"></p-tag>
                                </td>
                                <td><span class="p-column-title">Date Sent</span>
                                    {{message.audit?.createdDate}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <p-paginator [rows]="10" [showCurrentPageReport] = "true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" [totalRecords]="totalRecords"  (onPageChange)="paginate($event)"></p-paginator>
                </p-tabPanel>
            </p-tabView>
            

            <!-- <p-dataView #dv [value]="jobs" [paginator]="true" [rows]="9" layout="list">

                <ng-template pTemplate="header">
					<div class="grid grid-nogutter">
						<div class="col-6 text-left">
                            <button pButton pRipple label="New Message" icon="pi pi-plus" class="p-button-success mr-2" (click)="openNew()"></button>
						</div>
						<div class="col-6 text-right">
						</div>
					</div>
				</ng-template>

                <ng-template let-job let-i="rowIndex" pTemplate="listItem">
					<div class="col-12">
						<div class="flex flex-column md:flex-row align-items-center p-3 w-full">
							<div class="flex-1 text-center md:text-left">
								<div class="font-bold flex text-xl">
                                    <span class="mr-2">#{{job.id}}</span>  
                                </div>
								<div class="flex align-items-center mt-3">
									<i class="pi pi-directions mr-2"></i>
									<p><span class="font-semibold">To :</span><span class="ml-2">{{job.to}}</span></p>
								</div>
								<div class="flex align-items-center mt-3">
									<i class="pi pi-directions-alt mr-2"></i>
									<p><span class="font-semibold">From :</span><span class="ml-2">{{job.from}}</span></p>
								</div>
                                <div class="flex align-items-center mt-3">
									<i class="pi pi-calendar mr-2"></i>
									<p><span class="font-semibold">Date :</span><span class="ml-2">{{job.date}}</span></p>
								</div>
                                <div class="flex align-items-center mt-3">
									<i class="pi pi-comment mr-2"></i>
									<p><span class="font-semibold">Message :</span><span class="ml-2">{{job.message}}</span></p>
								</div>
							</div>
						</div>
					</div>
				</ng-template>
			</p-dataView> -->
        </div>

        <p-dialog [(visible)]="messageFormDialog" [style]="{width: '450px', minHeight:'400px'}" header="New Message" [modal]="true" class="p-fluid" (onHide)="hideDialog()">
            <ng-template pTemplate="content">
                <form [formGroup]="messageForm">
                <div class="field">
                    <label>Type of Message<span class="text-danger">*</span></label>
                    <p-dropdown [options]="messageType" (onChange)="onTypeChange($event)" placeholder="Select Message Type" optionLabel="name" optionValue="code" formControlName="type"></p-dropdown>
                    <span
                            class="text-danger"
                            *ngIf="
                            messageForm.controls.type.touched &&
                            messageForm.controls.type.invalid && submitted
                            "
                            >Type is required</span
                    >
                </div>
                <div *ngIf="this.messageForm.controls.type.value == 'TRAILER_TRUCK_MOVE'">
                    <div *ngIf="this.userType !== 'ROLE_CLIENT'" class="field">
                        <label>Select Client<span class="text-danger">*</span></label>
                        <p-dropdown placeholder="Select Client" formControlName="clients" [options]="clients" (onChange)="onClientChange($event)" optionLabel="clientName" optionValue="clientId"></p-dropdown>
                    </div>
                    <div class="field">
                        <label>To<span class="text-danger">*</span></label>
                        <p-multiSelect placeholder="Select Recipients" [options]="userList" formControlName="toUserIds" optionLabel="fullName" optionValue="userId"></p-multiSelect>
                    </div>
                    <div class="field">
                        <label>Trailer<span class="text-danger">*</span></label>
                        <p-autoComplete formControlName="trailer" (onFocus)="isInputTouched=true" (onBlur)="isInputTouched=true" (input)="onUnitNumberChange($event)" [suggestions]="filteredFleets" (onSelect)="onFleetSelect($event)" dataKey="trailer" (completeMethod)="filterFleets($event)" [dropdown]="true">
                            <ng-template let-fleet pTemplate="item">
                                <div>{{fleet.trailer}}</div>
                            </ng-template>
                        </p-autoComplete>
                        <span
                            class="text-danger"
                            *ngIf="
                            messageForm.controls.trailer.touched &&
                            messageForm.controls.trailer.invalid && submitted
                            "
                            >Trailer is required</span
                    >
                    </div>
                    <div class="field">
                        <label>Pickup Location<span class="text-danger">*</span></label>
                        <p-dropdown placeholder="Select Location" formControlName="pickupLocationId" (onChange)="onLocationChange($event,'pickup')" [options]="locationList" optionLabel="locationName" optionValue="locationId"></p-dropdown>
                    </div>
                    <div class="field">
                        <label>Pickup Dock/Spot#<span class="text-danger">*</span></label>
                        <p-dropdown placeholder="Select Docks/Spots" formControlName="pickupSpotId" [options]="pickupSpotList" optionLabel="spotAndStatus" optionValue="spotId"></p-dropdown>
                    </div>
                    <div class="field">
                        <label>Drop Location<span class="text-danger">*</span></label>
                        <p-dropdown placeholder="Select Location" formControlName="dropLocationId" (onChange)="onLocationChange($event,'drop')" [options]="locationList" optionLabel="locationName" optionValue="locationId"></p-dropdown>
                    </div>
                    <div class="field">
                        <label>Drop Dock/Spot#<span class="text-danger">*</span></label>
                        <p-dropdown placeholder="Select Docks/Spots" formControlName="dropSpotId" [options]="dropSpotList" optionLabel="spotAndStatus" optionValue="spotId"></p-dropdown>
                    </div>
                    <div class="field">
                        <label for="message">Message<span class="text-danger">*</span></label>
                        <textarea pInputTextarea autoResize rows="3" cols="30" formControlName="messageBody"></textarea>
                        <span
                            class="text-danger"
                            *ngIf="
                            messageForm.controls.messageBody.touched &&
                            messageForm.controls.messageBody.invalid && submitted
                            "
                            >Message Body is required</span
                        >
                    </div>
                    <div class="field col-12">
                        <div class="grid">
                            <div class="col-12">
                                <label htmlFor="messagePriorities">Message Priorities<span class="text-danger">*</span></label>
                            </div>
                            <div class="col-12 md:col-4">
                                <div class="field-radiobutton mb-0">
                                    <p-radioButton name="priority" value="HIGH" formControlName="priority" id="priority"></p-radioButton>
                                    <p-tag rounded="true" severity="danger" value="High"></p-tag>
                                </div>
                            </div>
                            <div class="col-12 md:col-4">
                                <div class="field-radiobutton">
                                    <p-radioButton name="priority" value="MEDIUM" formControlName="priority"
                                                id="priority2"></p-radioButton>
                                    <p-tag rounded="true" severity="primary" value="Medium"></p-tag>
                                </div>
                            </div>
                            <div class="col-12 md:col-4">
                                <div class="field-radiobutton">
                                    <p-radioButton name="priority" value="LOW" formControlName="priority" id="priority3"></p-radioButton>
                                    <p-tag rounded="true" severity="success" value="Low"></p-tag>
                                </div>
                            </div>
                            <span
                            class="text-danger"
                            *ngIf="
                            messageForm.controls.priority.touched &&
                            messageForm.controls.priority.invalid && submitted
                            "
                            >Priority is required</span
                            >
                        </div>
                    </div>
                </div>
                <div *ngIf="this.messageForm.controls.type.value == 'HOT_TRAILER'">
                    <div *ngIf="this.userType !== 'ROLE_CLIENT'" class="field">
                        <label>Select Client<span class="text-danger">*</span></label>
                        <p-dropdown placeholder="Select Client" formControlName="clients" [options]="clients" (onChange)="onClientChange($event)" optionLabel="clientName" optionValue="clientId"></p-dropdown>
                    </div>
                    <div class="field">
                        <label>To<span class="text-danger">*</span></label>
                        <p-multiSelect placeholder="Select Recipients" [options]="userList" formControlName="toUserIds" optionLabel="fullName" optionValue="userId"></p-multiSelect>
                    </div>
                    <div class="field">
                        <label>Client Location<span class="text-danger">*</span></label>
                        <p-dropdown placeholder="Select Location" formControlName="clientLocationId" [options]="locationList" optionLabel="locationName" optionValue="locationId"></p-dropdown>
                    </div>
                    <div *ngIf="!showHotTrailerNumberField" class="field">
                        <label>Hot Trailer#<span class="text-danger">*</span> <p-tag class="tag-hover" severity="success" (click)="showTrailerFields()" value="Add" icon="pi pi-plus"></p-tag></label>
                        <p-dropdown placeholder="Select Hot Trailer" formControlName="fleetId" (onChange)="onFleetChange($event)" [options]="fleetList" optionLabel="unitNumber" optionValue="fleetId"></p-dropdown>
                    </div>
                    <div *ngIf="showHotTrailerNumberField">
                        <p-tag class="tag-hover" severity="warning" (click)="showTrailerFields()" value="Back" icon="pi pi-directions-alt"></p-tag>
                        <div class="field">
                            <label>Carrier<span class="text-danger">*</span></label>
                            <input pInputText id="spot" type="text" formControlName="carrier" />
                        </div>
                        <div class="field">
                            <label>Hot Trailer#<span class="text-danger">*</span></label>
                            <!-- <p-dropdown placeholder="Select Trailer" formControlName="fleetId" (onChange)="onFleetChange($event)" [options]="fleetList" optionLabel="unitNumber" optionValue="fleetId"></p-dropdown>
                             -->
                            <input pInputText id="spot" type="text" formControlName="unitNumber" />
                            <span
                                class="text-danger"
                                *ngIf="
                                    messageForm.controls.unitNumber.touched &&
                                    messageForm.controls.unitNumber.invalid && submitted
                                "
                                >Hot tailer# is required</span
                            >
                        </div>
                    </div>
                    
                    <div class="field">
                        <label for="message">Message<span class="text-danger">*</span></label>
                        <textarea pInputTextarea autoResize formControlName="messageBody" rows="3" cols="30"></textarea>
                        <span
                        class="text-danger"
                        *ngIf="
                        messageForm.controls.messageBody.touched &&
                        messageForm.controls.messageBody.invalid && submitted
                        "
                        >Message Body is required</span
                    >
                    </div>
                    <div class="field col-12">
                        <div class="grid">
                            <div class="col-12">
                                <label htmlFor="messagePriorities">Message Priorities<span class="text-danger">*</span></label>
                            </div>
                            <div class="col-12 md:col-4">
                                <div class="field-radiobutton mb-0">
                                    <p-radioButton name="priority" value="HIGH" formControlName="priority" id="priority"></p-radioButton>
                                    <p-tag rounded="true" severity="danger" value="High"></p-tag>
                                </div>
                            </div>
                            <div class="col-12 md:col-4">
                                <div class="field-radiobutton">
                                    <p-radioButton name="priority" value="MEDIUM" formControlName="priority"
                                                id="priority2"></p-radioButton>
                                    <p-tag rounded="true" severity="primary" value="Medium"></p-tag>
                                </div>
                            </div>
                            <div class="col-12 md:col-4">
                                <div class="field-radiobutton">
                                    <p-radioButton name="priority" value="LOW" formControlName="priority" id="priority3"></p-radioButton>
                                    <p-tag rounded="true" severity="success" value="Low"></p-tag>
                                </div>
                            </div>
                            <span
                            class="text-danger"
                            *ngIf="
                            messageForm.controls.priority.touched &&
                            messageForm.controls.priority.invalid && submitted
                            "
                            >Priority is required</span
                            >
                        </div>
                    </div>
                </div>
                <div *ngIf="this.messageForm.controls.type.value == 'INFORMATION'">
                    <div class="field">
                        <label>To<span class="text-danger">*</span></label>
                        <p-multiSelect placeholder="Select Recipients" [options]="userList" formControlName="toUserIds" optionLabel="fullName" optionValue="userId"></p-multiSelect>
                    </div>
                    <div class="field">
                        <label for="message">Message<span class="text-danger">*</span></label>
                        <textarea pInputTextarea autoResize formControlName="messageBody" rows="3" cols="30"></textarea>
                        <span
                        class="text-danger"
                        *ngIf="
                        messageForm.controls.messageBody.touched &&
                        messageForm.controls.messageBody.invalid && submitted
                        "
                        >Message Body is required</span
                    >
                    </div>
                    <div class="field col-12">
                        <div class="grid">
                            <div class="col-12">
                                <label htmlFor="messagePriorities">Message Priorities<span class="text-danger">*</span></label>
                            </div>
                            <div class="col-12 md:col-4">
                                <div class="field-radiobutton mb-0">
                                    <p-radioButton name="priority" value="HIGH" formControlName="priority" id="priority"></p-radioButton>
                                    <p-tag rounded="true" severity="danger" value="High"></p-tag>
                                </div>
                            </div>
                            <div class="col-12 md:col-4">
                                <div class="field-radiobutton">
                                    <p-radioButton name="priority" value="MEDIUM" formControlName="priority"
                                                id="priority2"></p-radioButton>
                                    <p-tag rounded="true" severity="primary" value="Medium"></p-tag>
                                </div>
                            </div>
                            <div class="col-12 md:col-4">
                                <div class="field-radiobutton">
                                    <p-radioButton name="priority" value="LOW" formControlName="priority" id="priority3"></p-radioButton>
                                    <p-tag rounded="true" severity="success" value="Low"></p-tag>
                                </div>
                            </div>
                            <span
                            class="text-danger"
                            *ngIf="
                            messageForm.controls.priority.touched &&
                            messageForm.controls.priority.invalid && submitted
                            "
                            >Priority is required</span
                            >
                        </div>
                    </div>
                </div>
            </form>
            </ng-template>

            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
                <button pButton pRipple label="Send" icon="pi pi-check" class="p-button-text" (click)="sendMessage()"></button>
            </ng-template>
        </p-dialog>

        <p-dialog [(visible)]="readDialog" header="Message" [modal]="true" [style]="{width:'450px'}">
            <ng-template pTemplate="content">
                <div class="flex flex-column md:flex-row align-items-center p-3 w-full">
                    <div class="flex-1 text-center md:text-left">
                        <div class="flex align-items-center mt-3">
                            <i class="pi pi-directions mr-2"></i>
                            <p><span class="font-semibold">To :</span><span class="ml-2">{{modalMessage.toUser?.email}}</span></p>
                        </div>
                        <div class="flex align-items-center mt-3">
                            <i class="pi pi-directions-alt mr-2"></i>
                            <p><span class="font-semibold">From :</span><span class="ml-2">{{modalMessage.fromUser?.email}}</span></p>
                        </div>
                        <div class="flex align-items-center mt-3">
                            <i class="pi pi-calendar mr-2"></i>
                            <p><span class="font-semibold">Date :</span><span class="ml-2">{{modalMessage.audit?.createdDate}}</span></p>
                        </div>
                        <div *ngIf="modalMessage.type == 'TRAILER_TRUCK_MOVE'">
                            <div class="flex align-items-center mt-3">
                                <i class="pi pi-map-marker mr-2"></i>
                                <p><span class="font-semibold">Pickup Location :</span><span class="ml-2">{{modalMessage.pickupLocation.locationName}}</span></p>
                            </div>
                            <div class="flex align-items-center mt-3">
                                <i class="pi pi-map-marker mr-2"></i>
                                <p><span class="font-semibold">Pickup Parking Spot :</span><span class="ml-2">{{modalMessage.pickupSpot.spotName}}</span></p>
                            </div>
                            <div class="flex align-items-center mt-3">
                                <i class="pi pi-map-marker mr-2"></i>
                                <p><span class="font-semibold">Drop Location :</span><span class="ml-2">{{modalMessage.dropLocation.locationName}}</span></p>
                            </div>
                            <div class="flex align-items-center mt-3">
                                <i class="pi pi-map-marker mr-2"></i>
                                <p><span class="font-semibold">Drop Parking Spot :</span><span class="ml-2">{{modalMessage.dropSpot.spotName}}</span></p>
                            </div>
                        </div>
                        <div *ngIf="modalMessage.type == 'HOT_TRAILER'">
                            <div class="flex align-items-center mt-3">
                                <i class="pi pi-map-marker mr-2"></i>
                                <p><span class="font-semibold">Client Location :</span><span class="ml-2">{{modalMessage.clientLocation.locationName ? modalMessage.clientLocation.locationName : '-' }}</span></p>
                            </div>
                            <div class="flex align-items-center mt-3">
                                <i class="pi pi-map-marker mr-2"></i>
                                <p><span class="font-semibold">Hot Trailer# :</span><span class="ml-2">{{modalMessage.fleet.unitNumber ? modalMessage.fleet.unitNumber : '-'}}</span></p>
                            </div>
                            <div class="flex align-items-center mt-3">
                                <i class="pi pi-map-marker mr-2"></i>
                                <p><span class="font-semibold">Carrier :</span><span class="ml-2">{{modalMessage.fleet.carrier ? modalMessage.fleet.carrier : '-'}}</span></p>
                            </div>
                        </div>
                        <div class="flex align-items-center mt-3">
                            <i class="pi pi-comment mr-2"></i>
                            <p><span class="font-semibold">Message :</span><span class="ml-2">{{modalMessage.messageBody}}</span></p>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="footer">
                <button pButton pRipple label="Ok" icon="pi pi-check" class="p-button-success" (click)="hideMessage()"></button>
            </ng-template>
        </p-dialog>
    </div>
</div>
