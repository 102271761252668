import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { forkJoin, map, Subscription } from 'rxjs';
import { AppAlertService } from 'src/app/app-alert/service/app-alert.service';
import { AppLoaderService } from 'src/app/app-loader/service/app-loader.service';
import { APP_CONSTANTS } from 'src/app/constants/app.constants';
import { ErrorService } from 'src/app/error-handling/error.service';
import { IpagedQuery } from 'src/app/model/IpagedQuery';
import { TokenService } from 'src/app/security/token.service';
import { ManageClientsService } from '../manage-clients/manage-clients.service';
import { ManageLocationsService } from '../manage-locations/manage-locations.service';
import { ManageSpotsService } from '../manage-spots/manage-spots.service';
import { AddFleetService } from './add-fleet.service';

@Component({
  selector: 'app-add-fleet',
  templateUrl: './add-fleet.component.html',
  styleUrls: ['./add-fleet.component.scss']
})
export class AddFleetComponent implements OnInit {

  breadcrumbItems: MenuItem[];
  valCheck;
  deleteFleetDialog: boolean = false;
  fleetForm: FormGroup;

  dropdownItems = [
    { name: 'Truck', code: 'TRUCK' },
    { name: 'Trailer', code: 'TRAILER' },
    { name: 'Container', code: 'CONTAINER' },

  ];

  assignedList = [
    { name: 'Dynacraft', code: 'Dynacraft' },
    { name: 'Pegasus', code: 'Pegasus' }
  ];

  companyAssociated = [
    { name: 'Blair', code: 'Blair' },
    { name: 'Rental', code: 'Rental' },
    { name: 'Client', code: 'Client' }
  ];

  dockSpotList = [
    { name: 'D-82', code: 'D-82' },
    { name: 'S-123', code: 'S-123' },
    { name: 'D-N21', code: 'D-N21' }
  ];
  uniqueCarrierList: string[] = [];
  fleetId: any;
  subscription = new Subscription();
  clientList = [];
  query: IpagedQuery;
  userRoles: any;
  userType: string;
  clientsLocationsList: any;
  spotsList: any[];
  trailerStatus = [
    { name: 'Empty', code: 'EMPTY' },
    { name: 'Full', code: 'FULL' }
  ];
  deactivateFleetDialog: boolean;
  notAdminIT: boolean = false;

  constructor(
    private loader: AppLoaderService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private addFleetService: AddFleetService,
    private alertService: AppAlertService,
    private errorService: ErrorService,
    private manageClientsService: ManageClientsService,
    private tokenService: TokenService,
    private manageLocationsService: ManageLocationsService,
    private manageSpotsService: ManageSpotsService
  ) {
    this.fleetForm = this.fb.group({
      clientIds: [''],
      unitNumber: ['', Validators.required],
      carrier: ['', Validators.required],
      remarks: [''],
      type: ['', Validators.required],
      // owner: ['',Validators.required],
      fleetStatus: [null],
      spotId: [null],
      locationId: ['']
    });

    this.activatedRoute.queryParams.subscribe(qparams => {
      if (qparams["fleetId"]) {
        this.fleetId = qparams["fleetId"];
      }
    })
  }

  ngOnInit(): void {
    this.breadcrumbItems = [];
    this.breadcrumbItems.push({ label: 'Asset Inventory', routerLink: '../manage-fleets' });
    this.breadcrumbItems.push({ label: this.fleetId ? 'Update Asset' : 'Add Asset' });

    if (this.fleetId) {
      this.getFleetById(this.fleetId);
    }
    this.query = { isActive: true, size: 1000, page: 0 };
   
    this.userRoles = this.tokenService.getUserRoles();
    if (this.userRoles.some(role => role !== APP_CONSTANTS.USER_ROLES.ROLE_ADMIN && role !== APP_CONSTANTS.USER_ROLES.ROLE_IT)) {
      this.notAdminIT = true;
    }
    this.getClientList(this.query);

    if (this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_CLIENT && role !== APP_CONSTANTS.USER_ROLES.ROLE_ADMIN && role !== APP_CONSTANTS.USER_ROLES.ROLE_IT)) {
      this.userType = APP_CONSTANTS.USER_ROLES.ROLE_CLIENT;
      this.fleetForm.patchValue({
        clientIds: [this.tokenService.getUserClientId()]
      });
    }

    if (this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_SUPERVISOR)) {
          this.userType = APP_CONSTANTS.USER_ROLES.ROLE_SUPERVISOR;
    }
    if (this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_GUARD)) {
      this.dropdownItems = [
        { name: 'Trailer', code: 'TRAILER' },
        { name: 'Container', code: 'CONTAINER' },

      ];
    }
    // this.fleetForm.patchValue({
    //   owner:"A.Blair"
    // });

  }

  getClientList(query) {
    this.loader.show()
    this.subscription.add(
      this.manageClientsService.viewClients(query).subscribe(response => {
        this.clientList = response.list;

          if (this.notAdminIT) {
            let clientIds = [];
            clientIds[0] = this.clientList[0].clientId;
            this.fleetForm.patchValue({
              clientIds: clientIds
            });

            this.afterClientSelect();
          }
        
        this.loader.hide();
      }, (error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
      })
    )
  }

  getFleetById(fleetId) {
    this.loader.show();
    this.addFleetService.getFleetById(fleetId).subscribe(res => {
      this.fleetForm.patchValue(res);
      this.afterClientSelect(res);
      this.loader.hide();
    }, (error) => {
      this.loader.hide();
      this.errorService.handleError(error, true);
    })
  }

  onSubmit() {
    if (this.fleetForm.controls.clientIds.value == "") {
      this.fleetForm.patchValue({
        clientIds: []
      })
    }
    if (this.fleetForm.invalid) {
      this.fleetForm.markAllAsTouched();
    } else {
      this.loader.show();
      if (this.fleetId) {
        this.addFleetService.updateFleet(this.fleetId, this.fleetForm.value).subscribe(res => {
          this.loader.hide();
          this.alertService.alertSuccess([`Updated Successfully`]);
          this.router.navigate(['main/manage-fleets'])
        }, (error) => {
          this.loader.hide();
          this.errorService.handleError(error, true);
        })
      } else {
        this.addFleetService.saveFleet(this.fleetForm.value).subscribe(res => {
          this.loader.hide();
          this.alertService.alertSuccess([`Added Successfully`]);
          this.router.navigate(['main/manage-fleets'])
        }, (error) => {
          this.loader.hide();
          this.errorService.handleError(error, true);
        })
      }
    }
  }

  deleteProduct() {
    this.deleteFleetDialog = true;
  }

  deactivateFleet() {
    this.deactivateFleetDialog = true;
  }

  confirmDelete() {
    this.loader.show();
    this.subscription.add(
      this.addFleetService.deleteFleet(this.fleetId).subscribe(res => {
        this.deleteFleetDialog = false;
        this.alertService.alertSuccess([`Deleted Successfully`]);
        this.router.navigate(['main/manage-fleets'])
        this.loader.hide();
      }, (error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
      })
    )
  }

  confirmDeactivate() {
    this.loader.show();
    this.subscription.add(
      this.addFleetService.deactivateFleet(this.fleetId).subscribe(res => {
        this.deleteFleetDialog = false;
        this.alertService.alertSuccess([`Deactivated Successfully`]);
        this.router.navigate(['main/manage-fleets'])
        this.loader.hide();
      }, (error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
      })
    )
  }


  onClientSelect(event) {
    this.getClientLocations(this.query, event.value);
    this.fleetForm.patchValue({
      clientIds: [event.value]
    })
  }

  getClientLocations(query, clientId) {
    this.loader.show();
    this.subscription.add(
      this.manageLocationsService.viewLocations(query, clientId).subscribe(response => {
        this.clientsLocationsList = response.list;
        this.loader.hide();
      }, (error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
      })
    )
  }

  onLocationChange(event, editFleet?) {
    this.loader.show();
    let location = this.clientsLocationsList.find(loc => loc.locationId == event.value);
    this.manageSpotsService.viewSpots(this.query, location.clientId, location.locationId)
      .pipe(
        map(spots => {
          let spotsArray = [];
          for (let spot of spots.list) {
            let obj = {
              ...spot,
              spotAndStatus: this.spotAndFleetStatus(spot)
            };
            spotsArray.push(obj);
          }
          return {
            list: spotsArray,
            totalElements: spots.totalElements
          }

        })
      ).subscribe(res => {
        this.spotsList = res.list;
        if (editFleet && editFleet.spot) {
          this.fleetForm.patchValue({
            spotId: editFleet.spot.spotId
          })
        }
        this.loader.hide();
      }, (error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
      })
  }

  spotAndFleetStatus(spot) {
    if (spot.status == 'EMPTY' && spot?.fleet == null) {
      return `${spot.spotName} - Empty`
    } else if (spot.status == 'OCCUPIED' && spot?.fleet?.fleetStatus == 'FULL') {
      return `${spot.spotName} - ${spot?.fleet?.unitNumber} - Occupied Full Trailer`
    } else if (spot.status == 'OCCUPIED' && spot?.fleet?.fleetStatus == 'EMPTY') {
      return `${spot.spotName} - ${spot?.fleet?.unitNumber} - Occupied Empty Trailer`
    } else if (spot.status == 'TO_BE_EMPTY') {
      return spot?.fleet == null ? `${spot.spotName} - Scheduled for Pick-Up` : `${spot.spotName} - ${spot?.fleet?.unitNumber} - Scheduled for Pick-Up`
    } else if (spot.status == 'TO_BE_OCCUPIED') {
      return spot?.fleet == null ? `${spot.spotName} - Location Reserved` : `${spot.spotName} - ${spot?.fleet?.unitNumber} - Location Reserved`
    } else {
      return `${spot.spotName}`
    }

  }

  afterClientSelect(editFleet?) {
    if (this.fleetForm.value.clientIds != "") {
      this.loader.show();
      this.fleetForm.value.clientIds;
      forkJoin(
        this.fleetForm.value.clientIds.map(cId =>
          this.manageLocationsService.viewLocations(this.query, cId).pipe(
            map(location => {
              return location.list
            })
          )
        )
      ).subscribe(res => {
        // flatten the multi-dimensional array
        this.clientsLocationsList = this.flatten(res);
        if (editFleet && editFleet.spot) {
          if (this.clientsLocationsList.some(location => location.locationId == editFleet.spot.locationId)) {
            this.fleetForm.patchValue({
              locationId: editFleet?.spot?.locationId
            });
            this.onLocationChange({ value: editFleet.spot.locationId }, editFleet)
          } else {
            this.fleetForm.patchValue({
              locationId: '',
              spotId: null
            });
            this.spotsList = [];
          }
        } else {
          this.fleetForm.patchValue({
            locationId: '',
            spotId: null
          });
          this.spotsList = [];
        }
        this.loader.hide();
      }, (error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
      });
    } else {
      this.clientsLocationsList = [];
      this.spotsList = [];
      this.fleetForm.patchValue({
        locationId: '',
        spotId: null
      })
    }
  }

  flatten(arr) {
    return arr.reduce((flat, toFlatten) => {
      return flat.concat(Array.isArray(toFlatten) ? this.flatten(toFlatten) : toFlatten);
    }, []);
  }

  getCarrier(event) {

    console.log(event);

    this.addFleetService.getCarrier(event.query)
      .subscribe(response => {
        this.uniqueCarrierList = response;
        console.log(this.uniqueCarrierList);
        this.loader.hide();
      }, (error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
      });



  }

  clearCarrier() {
    this.uniqueCarrierList = null;
  }
}
