<div class="grid">
    <div class="col-12">
        <div class="card card-w-title">
            <p-breadcrumb [model]="breadcrumbItems" [home]="{icon: 'pi pi-home',routerLink:'../'}"></p-breadcrumb>
        </div>
    </div>
    <div class="col-12">
        <div class="card">
            <p-toast></p-toast>
            <p-toolbar styleClass="mb-4">
                <ng-template pTemplate="left">
                    <div class="my-2">
                        <h5 class="m-0">Users</h5>
                    </div>
                </ng-template>

                <ng-template pTemplate="right">
                    <button *ngIf="userType !== 'ROLE_CLIENT' && hideButtonsIfDriver !== true && hideButtonsIfGuard === false && hideButtonsIfSpotter === false" pButton pRipple label="New" icon="pi pi-plus" class="p-button-success mr-2" (click)="routeToAddUser()"></button>
                    <!-- <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help" (click)="exportExcel()"></button> -->

                    <p-splitButton *ngIf="hideButtonsIfDriver !== true && hideButtonsIfSpotter === false" label="Export" [model]="items" (onClick)="exportExcel()" styleClass="p-button-raised p-button-help mr-2 "></p-splitButton>
                        
                </ng-template>
            </p-toolbar>

            <p-table #dt [value]="userList" [loading]="loading" styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" [responsive]="true" [columns]="cols" [rows]="10"  [rowHover]="true" dataKey="id">
                <ng-template pTemplate="caption">
                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                        <span></span>
                        <span class="block mt-2 md:mt-0 p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="search()" [(ngModel)]="searchboxValue" placeholder="Search Name" />
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th></th>
                        <th pSortableColumn="firstName">First Name</th>
                        <th pSortableColumn="lastName">LastName</th>
                        <th pSortableColumn="status">Status</th>
                        <th pSortableColumn="client">Client</th>
                        <th pSortableColumn="role">Role</th>
                        <th pSortableColumn="email">Email</th>
                    </tr>
                    <tr>
                        <th></th>
                        <th>
                            <!-- <p-columnFilter type="text" field="name"></p-columnFilter> -->
                        </th>
                        <th>
                            <!-- <p-columnFilter type="text" field="country.name"></p-columnFilter> -->
                        </th>
                        <th>
                            <p-dropdown [options]="dropdownStatus" placeholder="Select Status" optionLabel="name" optionValue="code" (onChange)="filterUserByStatus($event)"></p-dropdown>
                        </th>
                        <th>
                            <p-dropdown *ngIf="userType !== 'ROLE_CLIENT'" [showClear]="true" placeholder="Select Client" [options]="clientList" (onChange)="filterUsersByClient($event)" optionLabel="clientName" optionValue="clientId"></p-dropdown>
                        </th>
                        <th>
                            <p-dropdown [options]="roleList" placeholder="Select Role" optionLabel="name" optionValue="code" [showClear]="true" (onChange)="filterUserByRole($event)"></p-dropdown>
                            <!-- <p-columnFilter type="boolean" field="verified"></p-columnFilter> -->
                        </th>
                        <th>

                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-user>
                    <tr>
                        <td>
                            <button *ngIf="userType !== 'ROLE_CLIENT' && hideButtonsIfDriver !== true 
                                            && hideButtonsIfGuard === false && hideButtonsIfSpotter === false 
                                            && !hasSupervisorRole(user)" 
                                    pButton pRipple icon="pi pi-pencil" pTooltip="Edit" 
                                    class="p-button-rounded p-button-warning mr-2" 
                                    (click)="routeToEditUser(user.userId)">
                            </button>
                            <button *ngIf="user.isActive === false && hideButtonsIfDriver !== true "  pButton pRipple icon="pi pi-refresh" pTooltip="Activate" class="p-button-rounded p-button-success mr-2" (click)="activateUser(user.userId)"></button>
                            <!-- <button pButton pRipple icon="pi pi-key" pTooltip="Reset Password" class="p-button-rounded p-button-primary" (click)="resetPassword(user.userId)"></button> -->
                        </td>
                        <td><span class="p-column-title">First Name</span>
                            {{user.firstName}}
                        </td>
                        <td>
                            <span class="p-column-title">Last Name</span>
                            {{user.lastName}}
                        </td>
                        <td *ngIf="user.isActive" style="color: green;">
                            <span class="p-column-title">Status</span>
                            <i class="pi pi-check"></i> {{user.isActive ? 'Active' : 'Inactive'}}
                        </td>
                        <td *ngIf="!user.isActive" style="color: red;">
                            <span class="p-column-title">Status</span>
                            <i class="pi pi-minus-circle"></i> {{user.isActive ? 'Active' : 'Inactive'}}
                        </td>
                        <td>
                            <span class="p-column-title">Client</span>
                            <span *ngFor="let client of user.clients;let i=index">
                                {{client ? client?.clientName : 'None'}} {{i < user.clients.length - 1 ? ',' : ''}}
                            </span>
                        </td>
                        <td>
                            <span class="p-column-title">Role</span>
                            <span *ngFor="let role of user.roles;let i=index">
                                {{role ? role?.roleName : 'None'}} {{i < user.roles.length - 1 ? ',' : ''}}
                            </span>
                            {{user.role}}
                        </td>
                        <td>
                            <span class="p-column-title">Email</span>
                            {{user.email}}
                        </td>
                       
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="7">No User found.</td>
                    </tr>
                </ng-template>
            </p-table>
            



            <p-paginator [rows]="100" [showCurrentPageReport] = "true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" [totalRecords]="totalRecords"  (onPageChange)="paginate($event)"></p-paginator>
            
        </div>
    </div>
    
    <p-dialog [(visible)]="resetPasswordModal" [style]="{width: '450px'}" header="New Password" [modal]="true" class="p-fluid">
        <ng-template pTemplate="content">
            <!-- <form [formGroup]="resetPasswordForm">
                <div class="field">
                    <label for="name">New Password</label>
                    <input type="text" pInputText id="name" formControlName="resetPassword" required autofocus [ngClass]="{'ng-invalid ng-dirty' : resetPasswordForm.controls.resetPassword.touched &&
                    resetPasswordForm.controls.resetPassword.invalid}"/>
                    <small class="ng-dirty ng-invalid" *ngIf="resetPasswordForm.controls.resetPassword.touched &&
                    resetPasswordForm.controls.resetPassword.invalid">New Password is required.</small>
                </div>
            </form> -->
            <div class="flex align-items-center justify-content-center">
                <h4 class="mb-0">{{password}}</h4>
            </div>
        </ng-template>

        <ng-template pTemplate="footer">
            <!-- <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="resetPasswordModal=false"></button> -->
            <button pButton pRipple label="Ok" icon="pi pi-check" class="p-button-text" type="button" (click)="resetPasswordModal=false"></button>
        </ng-template>
    </p-dialog>
</div>
