import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MenuItem } from 'primeng/api';
import { ManageClientsService } from '../manage-clients/manage-clients.service';
import { IpagedQuery } from 'src/app/model/IpagedQuery';
import { AppLoaderService } from 'src/app/app-loader/service/app-loader.service';
import { Subscription, map } from 'rxjs';
import { ErrorService } from 'src/app/error-handling/error.service';
import { ManageLocationsService } from '../manage-locations/manage-locations.service';
import { ManageSpotsService } from '../manage-spots/manage-spots.service';
import { ManageFleetsService } from '../manage-fleets/manage-fleets.service';
import { AddTrailerAuditService } from './add-trailer-audit.service';
import { AppAlertService } from 'src/app/app-alert/service/app-alert.service';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'app-add-trailer-audit',
  templateUrl: './add-trailer-audit.component.html',
  styleUrls: ['./add-trailer-audit.component.scss']
})
export class AddTrailerAuditComponent implements OnInit {
  
  trailerAuditForm: FormGroup;
  breadcrumbItems: MenuItem[];
  query:IpagedQuery;
  subscription = new Subscription();
  clientList = [];
  clientsLocationsList = [];
  spots = [];
  filteredFleets = [];
  trailerStatus = [
    {"name":"Empty","code":"Empty"},
    {"name":"Loaded","code":"Loaded"},
    {"name":"Processing","code":"Processing"}
  ]
  selectedArea:any;
  uniqueFleetId : string;
  carrier : string;

  constructor(private fb:FormBuilder,
              private manageClientService:ManageClientsService,
              private loader:AppLoaderService,
              private errorService:ErrorService,
              private manageLocationService:ManageLocationsService,
              private manageSpotService:ManageSpotsService,
              private manageFleetService:ManageFleetsService,
              private addTrailerAuditService:AddTrailerAuditService,
              private alertService:AppAlertService,
              private router:Router
    )
   {

    this.trailerAuditForm = this.fb.group({
     
      area: ['',Validators.required],
      slot: ['',Validators.required],
      fleetId: ['',Validators.required],
      trailerStatus: ['',Validators.required],
      notes: [''],
      carrier:['',Validators.required],
      locationId: ['',Validators.required],
      spotId: ['']
      
    });

    }

  ngOnInit(): void {

    this.query = {isActive:true,size:1500,page:0};
    this.breadcrumbItems = [];
    this.breadcrumbItems.push({ label: 'Trailer Audit',routerLink:'../manage-trailer-audit'});
    this.breadcrumbItems.push({ label: 'Add Trailer Audit'});
    this.getClientList(this.query);
   
  }



  getClientList(query) {
    this.loader.show()
    this.subscription.add(
      this.manageClientService.viewClients(query).subscribe(response=>{
        this.clientList = response.list;
        this.getClientLocations(this.query,this.clientList[0].clientId);
        //this.getClientFleetList();
        this.loader.hide();
      },(error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
    })
    )
    
  }

  
    
   
   
  

  getClientLocations(query,clientId) {
    this.loader.show();
    this.subscription.add(
        this.manageLocationService.viewLocations(query,clientId).subscribe(response=>{
            this.clientsLocationsList = response.list;
            
            this.loader.hide();
        },(error) => {
          this.loader.hide();
          this.errorService.handleError(error, true);
      })
    )
  }


  loadLocationSpots(event) {
    console.log("event",event.value.locationId);
    this.loader.show();
    this.selectedArea = event.value.locationName;
    this.trailerAuditForm.patchValue({
      area:event.value.locationName,
      locationId:event.value.locationId
    })
    
    let location = this.clientsLocationsList.find(loc => loc.locationId == event.value.locationId);
    this.manageSpotService.viewDropdownSpots(this.query,location.clientId,location.locationId)
    .subscribe(res=>{
       this.spots = res.list;
       
      this.loader.hide();
    },(error) => {
      this.loader.hide();
      this.errorService.handleError(error, true);
  })
  }



  

  checkIfHotTrailer(fleet) {
    if(fleet.isHotTrailer){
      return `${fleet.unitNumber} - (Hot Trailer)`
    }else{
      return `${fleet.unitNumber}`
    }
  }


  onFleetSelect(event){
   
    this.trailerAuditForm.patchValue({
      fleetId:event.fleetAndHotTrailer
    })
    this.uniqueFleetId = event.fleetId;
    this.carrier = event.carrier;
  }

  clearUniqueId(event){
    this.uniqueFleetId = null;
  }



  filterFleets(event) {
    // let filtered : any[] = [];
    // let query = event.query;

    // for(let i = 0; i < this.trailers.length; i++) {
    //     let fleet = this.trailers[i];
    //     if (fleet.fleetAndHotTrailer.toLowerCase().indexOf(query.toLowerCase()) == 0) {
    //         filtered.push(fleet);
    //     }
    // }

    // this.filteredFleets = filtered;
    console.log(event);
    
      this.manageFleetService.viewFleets(this.query,this.clientList[0].clientId,event.query)
      .pipe(
        map(fleets=>{
          let fleetsArray = [];
          for(let fleet of fleets.list){
              let obj = {
                  ...fleet,
                  fleetAndHotTrailer : this.checkIfHotTrailer(fleet)
                };
                fleetsArray.push(obj);
          }
          return {
            list : fleetsArray
          }

        })
      ).subscribe(response=>{
        this.filteredFleets = response.list;
        
        this.loader.hide();
      },(error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
    });

    
    
  }


  patchSpotName(event)
  {
    
    this.trailerAuditForm.patchValue({
      slot:event.value.spotName,
      spotId: event.value.spotId
    })


  }



  onSubmit()
  {
    if(this.trailerAuditForm.invalid){
      this.trailerAuditForm.markAllAsTouched();
    }else{
      this.trailerAuditForm.patchValue({
        fleetId: this.uniqueFleetId
      })
      this.loader.show();
      console.log("form",this.trailerAuditForm.value);
        this.addTrailerAuditService.saveTrailerAudit(this.trailerAuditForm.value).subscribe(res=>{
          this.loader.hide();
          this.alertService.alertSuccess(['Trailer Audit Added Successfully']);
          this.router.navigate(['main/manage-trailer-audit'])
        },(error) => {
          this.loader.hide();
          this.errorService.handleError(error, true);
      })
      
    }
  }

}
