import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { AppAlertService } from 'src/app/app-alert/service/app-alert.service';
import { AppLoaderService } from 'src/app/app-loader/service/app-loader.service';
import { ErrorService } from 'src/app/error-handling/error.service';
import { AddClientService } from './add-client.service';

@Component({
    selector: 'app-add-client',
    templateUrl: './add-client.component.html',
    styleUrls: ['./add-client.component.scss'],
})
export class AddClientComponent implements OnInit {
    breadcrumbItems: MenuItem[];
    clientForm: FormGroup;
    stateList = [
        { name: 'Alabama', code: 'AL' },
        { name: 'Alaska', code: 'AK' },
        { name: 'Arizona', code: 'AZ' },
        { name: 'Arkansas', code: 'AR' },
        { name: 'California', code: 'CA' },
        { name: 'Colorado', code: 'CO' },
        { name: 'Connecticut', code: 'CT' },
        { name: 'Delaware', code: 'DE' },
        { name: 'District Of Columbia', code: 'DC' },
        { name: 'Florida', code: 'FL' },
        { name: 'Georgia', code: 'GA' },
        { name: 'Hawaii', code: 'HI' },
        { name: 'Idaho', code: 'ID' },
        { name: 'Illinois', code: 'IL' },
        { name: 'Indiana', code: 'IN' },
        { name: 'Iowa', code: 'IA' },
        { name: 'Kansas', code: 'KS' },
        { name: 'Kentucky', code: 'KY' },
        { name: 'Louisiana', code: 'LA' },
        { name: 'Maine', code: 'ME' },
        { name: 'Maryland', code: 'MD' },
        { name: 'Massachusetts', code: 'MA' },
        { name: 'Michigan', code: 'MI' },
        { name: 'Minnesota', code: 'MN' },
        { name: 'Mississippi', code: 'MS' },
        { name: 'Missouri', code: 'MO' },
        { name: 'Montana', code: 'MT' },
        { name: 'Nebraska', code: 'NE' },
        { name: 'Nevada', code: 'NV' },
        { name: 'New Hampshire', code: 'NH' },
        { name: 'New Jersey', code: 'NJ' },
        { name: 'New Mexico', code: 'NM' },
        { name: 'New York', code: 'NY' },
        { name: 'North Carolina', code: 'NC' },
        { name: 'North Dakota', code: 'ND' },
        { name: 'Ohio', code: 'OH' },
        { name: 'Oklahoma', code: 'OK' },
        { name: 'Oregon', code: 'OR' },
        { name: 'Pennsylvania', code: 'PA' },
        { name: 'Rhode Island', code: 'RI' },
        { name: 'South Carolina', code: 'SC' },
        { name: 'South Dakota', code: 'SD' },
        { name: 'Tennessee', code: 'TN' },
        { name: 'Texas', code: 'TX' },
        { name: 'Utah', code: 'UT' },
        { name: 'Vermont', code: 'VT' },
        { name: 'Virginia', code: 'VA' },
        { name: 'Washington', code: 'WA' },
        { name: 'West Virginia', code: 'WV' },
        { name: 'Wisconsin', code: 'WI' },
        { name: 'Wyoming', code: 'WY' },
    ];

    timeZoneList = [
     {zone: 'America/Adak'},
     {zone: 'America/Anchorage'},
     {zone: 'America/Boise'},
     {zone:'America/Chicago'},
     {zone: 'America/Denver'},
     {zone: 'America/Detroit'},
     {zone: 'America/Fort_Wayne'},
     {zone: 'America/Indiana/Indianapolis'},
     {zone: 'America/Indiana/Knox'},
     {zone: 'America/Indiana/Marengo'},
     {zone: 'America/Indiana/Petersburg'},
     {zone: 'America/Indiana/Tell_City'},
     {zone: 'America/Indiana/Vevay'},
     {zone: 'America/Indiana/Vincennes'},
     {zone: 'America/Indiana/Winamac'},
     {zone: 'America/Indianapolis'},
     {zone: 'America/Juneau'},
     {zone: 'America/Kentucky/Louisville'},
     {zone: 'America/Kentucky/Monticello'},
     {zone: 'America/Knox_IN'},
     {zone: 'America/Los_Angeles'},
     {zone: 'America/Louisville'},
     {zone: 'America/Menominee'},
     {zone:  'America/Metlakatla'},
     {zone:  'America/New_York'},
     {zone:  'America/Nome'},
     {zone:  'America/North_Dakota/Beulah'},
     {zone:  'America/North_Dakota/Center'},
     {zone:   'America/North_Dakota/New_Salem'},
     {zone:  'America/Phoenix'},
     {zone:  'America/Shiprock'},
     {zone:  'America/Sitka'},
     {zone:  'America/Yakutat'},
     {zone:  'Navajo'},
     {zone:  'Pacific/Honolulu'},
     {zone:  'Pacific/Johnston'},
     {zone:  'US/Alaska'},
     {zone:  'US/Aleutian'},
     {zone:  'US/Arizona'},
     {zone:  'US/Central'},
     {zone:  'US/East-Indiana'},
     {zone:  'US/Eastern'},
     {zone:  'US/Hawaii'},
     {zone:  'US/Indiana-Starke'},
     {zone:  'US/Michigan'},
     {zone:  'US/Mountain'},
     {zone:  'US/Pacific'}
    ];  
  clientId: any;
  fromDetails: any;
  @ViewChild('search')
  public searchElementRef: ElementRef;
  private geoCoder;

    constructor(private fb:FormBuilder,
      private addClientService:AddClientService,
      private loader:AppLoaderService,
      private alertService:AppAlertService,
      private router:Router,
      private activatedRoute:ActivatedRoute,
      private errorService:ErrorService) {
      this.clientForm = this.fb.group({
        clientName: ['',Validators.required],
        contactPerson: ['',Validators.required],
        contactEmail: ['',[Validators.required,Validators.email]],
        contactPhone: [''],
        street: ['',Validators.required],
        city: ['',Validators.required],
        country: ['USA',Validators.required],
        state: ['',Validators.required],
        zip: ['',Validators.required],
        timeZone:['',Validators.required]
      });

      this.activatedRoute.queryParams.subscribe(qparams=>{
        if(qparams["clientId"]){
          this.clientId = qparams["clientId"];
        }
        if(qparams["fromDetails"]){
          this.fromDetails = qparams["fromDetails"];
        }
      })
    }

    ngOnInit(): void {
        this.breadcrumbItems = [];
        this.breadcrumbItems.push({
            label: 'Clients',
            routerLink: '../manage-clients',
        });
        this.breadcrumbItems.push({ label: this.clientId ? 'Edit Client' : 'Add Client' });

        if(this.clientId){
          this.getClientById(this.clientId);
        }


      //   this.mapsAPILoader.load().then(() => {
      //     this.geoCoder = new google.maps.Geocoder;
      //     const options = {
      //       componentRestrictions: { country: "us" }
      //     };
      //     let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement,options);
      //     autocomplete.addListener("place_changed", () => {
      //       this.ngZone.run(() => {
      //         this.loader.show();
      //         var place: google.maps.places.PlaceResult = autocomplete.getPlace();
      //         if (place.geometry === undefined || place.geometry === null) {
      //           return;
      //         }
  
      //         this.clientForm.patchValue({
      //           latitude:place.geometry.location.lat(),
      //           longitude: place.geometry.location.lng()
      //         })
      //       });
  
      //       const place = autocomplete.getPlace();
      //       let address1 = "";
      //       let postcode = "";
  
      //       for (const component of place.address_components) {
      //         const componentType = component.types[0];
  
      //         switch (componentType) {
      //           case "street_number": {
      //             address1 = `${component.long_name} ${address1}`;
      //             break;
      //           }
  
      //           case "route": {
      //             address1 += component.short_name;
      //             break;
      //           }
  
      //           case "postal_code": {
      //             postcode = `${component.long_name}${postcode}`;
      //             break;
      //           }
  
      //           case "postal_code_suffix": {
      //             postcode = `${postcode}-${component.long_name}`;
      //             break;
      //           }
      //           case "locality":
      //             this.clientForm.patchValue({
      //               city:component.long_name
      //             });
      //             break;
  
      //           case "administrative_area_level_1": {
      //             this.clientForm.patchValue({
      //               state:component.short_name
      //             })
      //             break;
      //           }
      //           case "country":
      //             this.clientForm.patchValue({
      //               country:component.short_name
      //             })
      //           break;
      //         }
      //       }
  
      //       this.clientForm.patchValue({
      //         street:address1,
      //         zip:postcode
      //       })
  
      //       this.loader.hide();
  
      //     })
      // });
    }

    getClientById(clientId){
      this.loader.show();
      this.addClientService.getClientById(clientId).subscribe(res=>{
        this.clientForm.patchValue(res);
                this.loader.hide();
      },(error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
    })
    }

    onSubmit(){
      if(this.clientForm.invalid){
        this.clientForm.markAllAsTouched();
      }else{
        this.loader.show();
        if(this.clientId){
          this.addClientService.updateClient(this.clientId,this.clientForm.value).subscribe(res=>{
            this.loader.hide();
            this.alertService.alertSuccess(['Client Updated Successfully']);
            if(!this.fromDetails){
              this.router.navigate(['main/manage-clients'])
            }else{
              this.router.navigate(['/main/client-details'],{queryParams:{clientId:this.clientId}});
            }
          },(error) => {
            this.loader.hide();
            this.errorService.handleError(error, true);
        })
        }else{
          this.addClientService.saveClient(this.clientForm.value).subscribe(res=>{
            this.loader.hide();
            this.alertService.alertSuccess(['Client Added Successfully. Config features can be enabled by clicking flag icon ']);
            if(!this.fromDetails){
              this.router.navigate(['main/manage-clients'])
            }else{
              this.router.navigate(['/main/client-details'],{queryParams:{clientId:this.clientId}});
            }
          },(error) => {
            this.loader.hide();
            this.errorService.handleError(error, true);
        })
        }
      }
    }
}
