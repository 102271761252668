import { Component, OnInit } from '@angular/core';
import { Product } from '../../api/product';
import { ProductService } from '../../service/productservice';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { concatMap, map, Subscription } from 'rxjs';
import { ManageLocationsService } from '../manage-locations/manage-locations.service';
import { ManageMessagesService } from './manage-messages.service';
import { IpagedQuery } from 'src/app/model/IpagedQuery';
import { ManageClientsService } from '../manage-clients/manage-clients.service';
import { ManageSpotsService } from '../manage-spots/manage-spots.service';
import { AppLoaderService } from 'src/app/app-loader/service/app-loader.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ManageUsersService } from '../manage-users/manage-users.service';
import { TokenService } from 'src/app/security/token.service';
import { AppAlertService } from 'src/app/app-alert/service/app-alert.service';
import { ManageFleetsService } from '../manage-fleets/manage-fleets.service';
import { APP_CONSTANTS } from 'src/app/constants/app.constants';
import { ErrorService } from 'src/app/error-handling/error.service';
import { AddFleetService } from '../add-fleet/add-fleet.service';
import { WebSocketService } from 'src/app/web-socket.service';
import { DashboardService } from '../dashboard/dashboard.service';
import * as moment from 'moment';
import { DataService } from 'src/app/service/data.service';
import { AddJobService } from '../add-job/add-job.service';

@Component({
  selector: 'app-manage-messages',
  templateUrl: './manage-messages.component.html',
  providers: [MessageService, ConfirmationService],
  styleUrls: ['../../../assets/demo/badges.scss', './manage-messages.component.scss']
})
export class ManageMessagesComponent implements OnInit {

  breadcrumbItems: MenuItem[];

  productDialog: boolean;

  readDialog: boolean = false;

  supervisor = "Supervisor";

  subscription = new Subscription();

  // messages : any;


  deleteProductsDialog: boolean = false;

  messages = [
    // {
    //     id:"1",
    //     from: 'admin@maildrop.cc',
    //     to: 'dynacraft@maildrop.cc',
    //     message: 'DD14-This trailer has racks and needs to be moved to the left slightly',
    //     date: '01/01/2022'
    // },
    // {
    //   id:"2",
    //   from: 'admin@maildrop.cc',
    //   to: 'pegasus@maildrop.cc',
    //   message: 'DD14-This trailer has racks and needs to be moved to the left slightly',
    //   date: '01/01/2022'
    // },
    // {
    //   id:"3",
    //   from: 'admin@maildrop.cc',
    //   to: 'dynacraft@maildrop.cc',
    //   message: 'DD14-This trailer has racks and needs to be moved to the left slightly',
    //   date: '01/01/2022'
    // },
    // {
    //   id:"4",
    //   from: 'admin@maildrop.cc',
    //   to: 'dynacraft@maildrop.cc',
    //   message: 'DD14-This trailer has racks and needs to be moved to the left slightly',
    //   date: '01/01/2022'
    // }
  ];

  product: Product;

  selectedProducts: Product[];

  submitted: boolean;

  cols: any[];

  statuses: any[];

  type: any[];

  query: IpagedQuery;

  clients: any[];

  actualMessages = [];



  rowsPerPageOptions = [5, 10, 20];

  modalTitle;
  modalMessage: any;
  messageType: { name: string; code: string; }[];
  clientLocations: { name: string; value: string; }[];
  clientLocations2: { name: string; value: string; }[];
  loading: boolean;
  totalRecords: any;
  locationList: any;
  spotList: any;
  drpQuery: { isActive: boolean; size: number; page: number; };
  clientId: any;
  locationId: any;
  messageForm: FormGroup;
  pickupSpotList: any;
  dropSpotList: any;
  userList;
  loggedInUserId;
  messageFormDialog = false;
  fleetList: any[];
  userRoles: any;
  userType: string;
  fleetForm: FormGroup;
  messageStatus: { name: string; code: string; }[];
  filterByReadAndUnread: any = null;
  inputOutbox = "INBOX";
  showHotTrailerNumberField: boolean;
  private webSocket: WebSocket;
  todayQuery: IpagedQuery;
  messageStatistics = {
    newCount: 0,
    readCount: 0
  }
  filteredFleets: any[] = [];
  unreadMessage: boolean = false;
  notAdminIT: boolean = false;
  replyDialog: boolean = false;
  replyForm: FormGroup;
  replyMessage: any;
  replySubmitted: boolean = false;
  jobForm: FormGroup;
  jobCheck: boolean = false;
  pickupSpots = [];
  dropSpots = [];
  drivers = [];
  trailerStatus = [
    { name: 'Empty', code: 'EMPTY' },
    { name: 'Full', code: 'FULL' }
  ];
  jobSubmitted: boolean = false;
  newfleetForm: FormGroup;
  addFleetDialogConfirm: boolean = false;
  trailerSubmitted: boolean = false;
  addTrailerHeader: String = "Confirm";
  addFleetDialog: boolean = false;
  dropdownItems = [
    { name: 'Truck', code: 'TRUCK' },
    { name: 'Trailer', code: 'TRAILER' },
    { name: 'Container', code: 'CONTAINER' },

  ];
  uniqueCarrierList: string[] = [];
  isSupervisorItAdmin: boolean = false;
  isNewTrailer: boolean = false;
  isInputTouched: boolean;
  messapegPopupTitle: string = "Message";
  activeTab: number = 0;
  isTrailerFoundInList: boolean = false;

  constructor(private router: Router,
    private manageMessagesService: ManageMessagesService,
    private manageClientsService: ManageClientsService,
    private manageLocationsService: ManageLocationsService,
    private manageSpotsService: ManageSpotsService,
    private manageFleetsService: ManageFleetsService,
    private loader: AppLoaderService,
    private fb: FormBuilder,
    private manageUsersService: ManageUsersService,
    private tokenService: TokenService,
    private alertService: AppAlertService,
    private errorService: ErrorService,
    private addFleetService: AddFleetService,
    private webSocketService: WebSocketService,
    private dashboardService: DashboardService,
    private dataService: DataService,
    private addJobService: AddJobService) {

    this.webSocket = new WebSocket(APP_CONSTANTS.SOCKET_URL);
    this.webSocket.onmessage = (event) => {

      this.getMessageStatics();
      this.getMessages(this.query, null, this.inputOutbox);
      console.log("messages");


    };

    this.messageForm = this.fb.group({
      dropLocationId: [''],
      dropSpotId: [''],
      messageBody: ['', Validators.required],
      pickupLocationId: [''],
      pickupSpotId: [''],
      toUserIds: [''],
      type: ['', Validators.required],
      clientLocationId: [''],
      fleetId: [''],
      carrier: [''],
      priority: ['', Validators.required],
      unitNumber: [''],
      trailer: [''],
      clients: [''],
      sequenceAsn: [''],
      trailerType: ['']
    });

    this.replyForm = this.fb.group({
      type: [''],
      dropLocationId: [''],
      dropSpotId: [''],
      messageBody: ['', Validators.required],
      pickupLocationId: [''],
      pickupSpotId: [''],
      toUserIds: [''],
      fleetId: [''],
      carrier: [''],
      unitNumber: [''],
      clientLocationId: [''],
      clients: [''],
      sequenceAsn: ['']
    });

    this.fleetForm = this.fb.group({
      carrier: [''],
      unitNumber: [''],
      isHotTrailer: [true],
      type: ['TRAILER'],
      clientIds: ['']
    });

    this.jobForm = this.fb.group({
      assignedToUserId: ['', Validators.required],
      description: [''],
      dropLocationId: ['', Validators.required],
      dropSpotId: [''],
      fleetId: ['', Validators.required],
      fleetStatus: [null],
      pickupLocationId: ['', Validators.required],
      pickupSpotId: ['', Validators.required],
      priority: ['', Validators.required],
      clientId: ['', Validators.required],
      isEdit: [''],
      trailer: ['', Validators.required],
      sequenceAsn: ['', Validators.required],
      messageId: [''],
      replyUserId:['']
      // bucket: ['']
    });

    this.newfleetForm = this.fb.group({
      clientIds: ['', Validators.required],
      unitNumber: ['', Validators.required],
      carrier: ['', Validators.required],
      remarks: [''],
      type: ['', Validators.required]
    });

    this.messageForm.get('type').valueChanges.subscribe(val => {
      if (this.messageForm.get('type').value == 'HOT_TRAILER') {
        this.messageForm.controls['unitNumber'].setValidators([Validators.required]);
      } else {
        this.messageForm.controls['unitNumber'].clearValidators();
      }
      this.messageForm.controls['unitNumber'].updateValueAndValidity();

      if (this.messageForm.get('type').value == 'TRAILER_TRUCK_MOVE') {
        this.messageForm.controls['trailer'].setValidators([Validators.required]);
        this.messageForm.controls['fleetId'].setValidators([Validators.required]);
        this.messageForm.controls['sequenceAsn'].setValidators([Validators.required]);
      } else {
        this.messageForm.controls['trailer'].clearValidators();
        this.messageForm.controls['fleetId'].clearValidators();
        this.messageForm.controls['sequenceAsn'].clearValidators();
      }
      this.messageForm.controls['trailer'].updateValueAndValidity();
      this.messageForm.controls['fleetId'].updateValueAndValidity();
      this.messageForm.controls['sequenceAsn'].updateValueAndValidity();
    });
  }

  ngOnInit() {

    this.userRoles = this.tokenService.getUserRoles();
    if (this.userRoles.some(role => role !== APP_CONSTANTS.USER_ROLES.ROLE_ADMIN && role !== APP_CONSTANTS.USER_ROLES.ROLE_IT)) {
      this.notAdminIT = true;
    }
    if (this.userRoles.some(role => role == APP_CONSTANTS.USER_ROLES.ROLE_SUPERVISOR || role == APP_CONSTANTS.USER_ROLES.ROLE_IT || role == APP_CONSTANTS.USER_ROLES.ROLE_ADMIN)) {
      this.isSupervisorItAdmin = true;
    }

    let nowTime = moment().format('yyyy-MM-DD HH:mm');
    let twentyFourHours = moment().add(-24, 'hours').format('yyyy-MM-DD HH:mm');
    this.todayQuery = {
      fromDateTime: twentyFourHours,
      toDateTime: nowTime
    }
    this.query = { isActive: true, size: 10, page: 0 };
    this.drpQuery = { isActive: true, size: 1000, page: 0 };
    this.viewClients(this.drpQuery);
    this.viewUsers(this.drpQuery)
    // this.getMessages(this.query, null, this.inputOutbox);
    this.loggedInUserId = this.tokenService.getUserId();

    if (this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_CLIENT && role !== APP_CONSTANTS.USER_ROLES.ROLE_ADMIN && role !== APP_CONSTANTS.USER_ROLES.ROLE_IT)) {
      this.userType = APP_CONSTANTS.USER_ROLES.ROLE_CLIENT;
      this.clientId = this.tokenService.getUserClientId();
      this.fleetForm.patchValue({
        clientIds: [this.tokenService.getUserClientId()]
      });
      this.getClientLocations(this.drpQuery, this.tokenService.getUserClientId());
      this.getFleetList(this.drpQuery, this.tokenService.getUserClientId());
      this.activeTab = 1;
      this.inputOutbox = "OUTBOX";
    }
    this.getMessages(this.query, null, this.inputOutbox);

    // this.productService.getProducts().then(data => this.products = data);

    this.breadcrumbItems = [];
    this.breadcrumbItems.push({ label: 'Messages' });

    // this.clients = [
    //   {name: 'Supervisor', value: 'Pegasus'},
    //   {name: 'Blair Enterprises', value: 'Dynacraft'},
    // ];

    // this.clientLocations = [
    //   {name: 'Location 1', value: '1'},
    //   {name: 'Location 2', value: '2'},
    //   {name: 'Location 2', value: '3'},
    //   {name: 'Location 2', value: '4'}
    // ];

    // this.clientLocations2 = [
    //   {name: 'Location 1', value: '1'},
    //   {name: 'Location 2', value: '2'},
    //   {name: 'Location 2', value: '3'},
    //   {name: 'Location 2', value: '4'}
    // ];

    this.messageType = [
      { name: 'Trailer/Container Move', code: 'TRAILER_TRUCK_MOVE' },
      { name: 'Hot Trailer', code: 'HOT_TRAILER' },
      { name: 'Information', code: 'INFORMATION' }
    ];

    this.messageStatus = [
      { name: 'Read', code: 'READ' },
      { name: 'Unread', code: 'NEW' }
    ]
  }

  getMessageStatics() {
    this.dashboardService.getMessagesCount(this.todayQuery).subscribe(response => {
      this.messageStatistics = response;
      if (this.messageStatistics.newCount != 0) {
        this.unreadMessage = true;
      }

      this.dashboardService.sendMessageCount(this.messageStatistics.newCount);
    }
    )
  }

  viewClients(query: IpagedQuery) {
    this.loader.show();
    this.manageClientsService.viewClients(query).subscribe(response => {
      this.clients = response.list;
      if (this.notAdminIT) {
        this.messageForm.patchValue({
          clients: this.clients[0].clientId
        });
        this.replyForm.patchValue({
          clients: this.clients[0].clientId
        });
        this.newfleetForm.patchValue({
          clientIds: [this.clients[0].clientId]
        });
        this.clientId = this.clients[0].clientId;
        this.getClientLocations(this.drpQuery, this.clientId);
        this.getFleetList(this.drpQuery, this.clientId);
        this.fleetForm.patchValue({
          clientIds: [this.clientId]
        })
      }
      this.loader.hide();
    }, (error) => {
      this.loader.hide();
      this.errorService.handleError(error, true);
    })
  }

  getClientLocations(query, clientId) {
    this.loader.show();
    this.subscription.add(
      this.manageLocationsService.viewLocations(query, clientId).subscribe(response => {
        this.locationList = response.list;
        this.loader.hide();
      }, (error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
      })
    )
  }

  getClientSpots(query, clientId, locationId, type) {
    this.loader.show();
    this.subscription.add(
      this.manageSpotsService.viewDropdownSpots(query, clientId, locationId)
        .pipe(
          map(spots => {
            let spotsArray = [];
            for (let spot of spots.list) {
              let obj = {
                ...spot,
                spotAndStatus: this.spotAndFleetStatus(spot)
              };
              spotsArray.push(obj);
            }
            return {
              list: spotsArray
            }

          })
        ).subscribe(response => {
          type == 'pickup' ? this.pickupSpotList = response.list : this.dropSpotList = response.list;
          this.loader.hide();
        }, (error) => {
          this.loader.hide();
          this.errorService.handleError(error, true);
        })
    )
  }

  getMessages(query, filter?: any, inboxOutbox?: any) {
    this.loading = true;
    this.manageMessagesService.viewMessages(query, filter, inboxOutbox).subscribe(response => {
      this.messages = response.list;

      this.totalRecords = response.totalElements;
      this.loading = false;
    }, (error) => {
      this.loader.hide();
      this.errorService.handleError(error, true);
    })

  }

  onClientChange(event) {
    this.clientId = event.value;
    this.getClientLocations(this.drpQuery, this.clientId);
    this.viewUsers(this.drpQuery, this.clientId);
    this.getFleetList(this.drpQuery, this.clientId);
    this.fleetForm.patchValue({
      clientIds: [this.clientId]
    })


  }

  onLocationChange(event, type) {
    this.locationId = event.value
    console.log(this.locationId);
    this.getClientSpots(this.drpQuery, this.clientId, this.locationId, type)

  }

  hideDialog() {
    this.messageForm.reset();
    this.messageFormDialog = false;

    this.submitted = false;
    this.isNewTrailer = false;
  }

  saveProduct() {
    this.submitted = true;
  }

  openNew() {
    this.messageFormDialog = true;
    this.viewUsers(this.drpQuery);
  }

  readMessage(message, status, type: any = 0) {

    if (message.toUser.userId === this.loggedInUserId) {
      if (status == 'changeStatus') {
        this.manageMessagesService.changeMessageStatus(message.messageId, { status: 'READ' }).subscribe(res => {
          this.getMessages(this.query, this.filterByReadAndUnread, this.inputOutbox);
        }, (error) => {
          this.loader.hide();
          this.errorService.handleError(error, true);
        })
      }
    }
    if (message.type == "TRAILER_TRUCK_MOVE") {
      let client = this.clients.filter((x: any) => { return x.clientId == message.dropLocation.clientId });
      if(client.length > 0){
        message.clientName = client[0].clientName;
      }

      if(message.clientName != undefined){
        this.getClientLocations(this.drpQuery, message.pickupLocation?.clientId);
        this.getSpots(this.query, message.pickupLocation?.clientId, message.pickupLocation?.locationId, 'pickup');
        this.getSpots(this.query, message.pickupLocation?.clientId, message.dropLocation?.locationId, 'drop');
      }

    } else if (message.type == "HOT_TRAILER") {
      let client = this.clients.filter((x: any) => { return x.clientId == message.clientLocation.clientId });
      if(client.length > 0){
        message.clientName = client[0].clientName;
      }
    }
    this.modalMessage = message;
    this.modalMessage.messageType = type;
    console.log(this.modalMessage);
    if (this.modalMessage.job) {

    } else {
      this.modalMessage.job = null;
    }

    this.readDialog = true;

  }

  hideMessage() {
    if (this.jobCheck) {
      this.jobCheck = false;
      this.jobForm.reset();
    } else if (this.replyDialog) {
      this.replyDialog = false;
      this.replyForm.reset();
    }
    this.readDialog = false;
    this.messapegPopupTitle = "Message";
  }


  viewUsers(query: IpagedQuery, clientId?: any) {
    this.loader.show();
    this.subscription.add(
      this.manageUsersService.viewUsers(query, clientId).
        pipe(
          map(res => {
            let users = [];
            for (let user of res.list) {
              let obj = {
                ...user,
                fullName: user.firstName + " " + user.lastName
              };
              users.push(obj);
            }
            return users
          })
        ).subscribe(users => {

          if (this.userRoles.some(role => role == APP_CONSTANTS.USER_ROLES.ROLE_SUPERVISOR || role == APP_CONSTANTS.USER_ROLES.ROLE_ADMIN || role == APP_CONSTANTS.USER_ROLES.ROLE_IT)) {
            // this.drivers.push(
            //   { fullName: 'Bucket System Driver', userId: 'BUCKET_DRIVER' },
            //   { fullName: 'Bucket System Spotter', userId: 'BUCKET_SPOTTER' },
            //  );

            let usersList = [];
            this.drivers = [];
            users.map(user => {
              for (let role of user["roles"]) {

                if (role.roleName === "DRIVER" || role.roleName === "SPOTTER" || role.roleName === "SUPERVISOR") {
                  this.drivers.push(user);
                }
              }
            });

          }
          // let usersList=[];
          // users.map(user=>{
          //   for(let role of user["roles"]){
          //     if(role.roleId == 'b09614be-3973-4f89-b237-ff3506ccdd70' || role.roleId == '4c8d3ec7-2dc6-46c3-afb0-c266a3448f26'){
          //       usersList.push(user);
          //     }
          //   }
          // })
          // this.userList = usersList;
          this.userList = users.filter(user => user.userId !== this.loggedInUserId);
          if (this.userType == APP_CONSTANTS.USER_ROLES.ROLE_CLIENT) {
            this.userList = this.userList.filter(user => {
              for (let role of user.roles) {
                if (role.roleName == "SUPERVISOR") {
                  return user;
                }
              }
            });

            this.getClientLocations(this.drpQuery, this.tokenService.getUserClientId());
            this.getFleetList(this.drpQuery, this.tokenService.getUserClientId());
          }
          this.loader.hide();
        }, (error) => {
          this.loader.hide();
          this.errorService.handleError(error, true);
        })
    )
  }

  getFleetList(query: IpagedQuery, clientId?: any) {
    this.loader.show();
    this.subscription.add(
      this.manageFleetsService.viewFleets(query, clientId)
        .pipe(
          map(res => {
            let fleets = [];
            for (let fleet of res.list) {
              let obj = {
                ...fleet,
                plateNumber_unitNumber: fleet.plateNumber + "-" + `(${fleet.unitNumber})`
              };
              fleets.push(obj);
            }
            return {
              list: fleets
            }
          })
        )
        .subscribe(response => {
          this.fleetList = response.list;
          this.fleetList = this.fleetList.filter(fleet => fleet.isHotTrailer === true);
          this.loader.hide();
        }, (error) => {
          this.loader.hide();
          this.errorService.handleError(error, true);
        })
    )
  }

  onFleetChange(event) {
    let fleet = this.fleetList.find(el => el.fleetId === event.value);
    this.messageForm.patchValue({
      carrier: fleet.carrier,
      unitNumber: fleet.unitNumber
    })
  }


  sendMessage() {
    this.submitted = true;
    console.log(this.messageForm);

    if (this.messageForm.invalid) {
      if((this.messageForm.value.fleetId == "" || this.messageForm.value.fleetId == undefined) && (this.messageForm.value.trailer != "" && this.messageForm.value.trailer != undefined)){
        let fleets_arr = this.filteredFleets.find((x:any) => x.unitNumber == this.messageForm.value.trailer);
        if(fleets_arr == undefined){
          this.isTrailerFoundInList = true;
        }else{
          this.isTrailerFoundInList = false;
        }
        
      }else{
        this.isTrailerFoundInList = false;
      }
      console.log(this.isTrailerFoundInList);
      
      this.messageForm.markAllAsTouched();
    } else {
      if (this.messageForm.get('type').value == 'HOT_TRAILER') {
        this.saveHotTrailer();
      } else if (this.messageForm.get('type').value == 'TRAILER_TRUCK_MOVE' && this.isNewTrailer) {
          this.saveTrailer();
      } else {
          this.loader.show();
          
          this.manageMessagesService.sendMessage(this.messageForm.value).subscribe(res => {
            this.loader.hide();
            this.alertService.alertSuccess(['Message sent successfully']);
            this.getMessages(this.query, null, this.inputOutbox);
            this.messageFormDialog = false;
            this.messageForm.reset();
            this.submitted = false;
          }, (error) => {
            this.loader.hide();
            this.errorService.handleError(error, true);
          })
      }
    }
  }

  paginate(event) {
    this.query.page = event.page;
    this.getMessages(this.query, this.filterByReadAndUnread, this.inputOutbox);

  }


  saveHotTrailer() {

    if (this.showHotTrailerNumberField == true) {
      this.messageForm.patchValue({
        fleetId: null
      })
    }

    if (this.messageForm.value.fleetId == null) {
      this.loader.show();
      this.fleetForm.patchValue({
        carrier: this.messageForm.get('carrier').value,
        unitNumber: this.messageForm.get('unitNumber').value
      });
      this.addFleetService.saveFleet(this.fleetForm.value)
        .pipe(
          concatMap(fleet => {
            this.messageForm.patchValue({
              fleetId: fleet.fleetId
            })
            return this.manageMessagesService.sendMessage(this.messageForm.value)
          })
        )
        .subscribe(res => {
          this.loader.hide();
          this.alertService.alertSuccess(['Message sent successfully']);
          this.getMessages(this.query, null, this.inputOutbox);
          this.messageFormDialog = false;
          this.messageForm.reset();
          this.submitted = false;
        }, (error) => {
          this.loader.hide();
          this.errorService.handleError(error, true);
        })
    } else {
      this.loader.show();
      this.manageMessagesService.sendMessage(this.messageForm.value).subscribe(res => {
        this.loader.hide();
        this.alertService.alertSuccess(['Message sent successfully']);
        this.getMessages(this.query, null, this.inputOutbox);
        this.messageFormDialog = false;
        this.messageForm.reset();
        this.submitted = false;
      }, (error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
      })
    }
  }

  saveTrailer(){

    let data = {
      clientIds: [this.messageForm.value.clients],
      unitNumber: this.messageForm.value.trailer,
      carrier: this.messageForm.value.carrier,
      type: this.messageForm.value.trailerType
    };
    this.addFleetService.saveFleet(data)
        .pipe(
          concatMap(fleet => {
            this.messageForm.patchValue({
              fleetId: fleet.fleetId
            })
            return this.manageMessagesService.sendMessage(this.messageForm.value)
          })
        )
        .subscribe(res => {
          this.loader.hide();
          this.alertService.alertSuccess(['Message sent successfully']);
          this.getMessages(this.query, null, this.inputOutbox);
          this.messageFormDialog = false;
          this.messageForm.reset();
          this.submitted = false;
          this.isNewTrailer = false;
        }, (error) => {
          this.loader.hide();
          this.errorService.handleError(error, true);
        });
  }

  filterMessagesByReadAndUnread(event) {
    this.filterByReadAndUnread = event.value;

    this.getMessages(this.query, this.filterByReadAndUnread, this.inputOutbox);
  }

  onTypeChange(event) {
    this.messageForm.reset();
    this.messageForm.patchValue({
      type: event.value
    })
    this.viewClients(this.drpQuery);
    this.viewUsers(this.drpQuery);
  }

  handleTabChange(event) {
    if (event.index == 0) {
      this.inputOutbox = "INBOX";
      this.getMessages(this.query, null, this.inputOutbox)
    } else {
      this.inputOutbox = "OUTBOX";
      this.getMessages(this.query, null, this.inputOutbox)
    }
  }

  spotAndFleetStatus(spot) {
    if (spot.status == 'EMPTY' && spot?.fleet == null) {
      return `${spot.spotName} - Empty`
    } else if (spot.status == 'OCCUPIED' && spot?.fleet?.fleetStatus == 'FULL') {
      return `${spot.spotName} - ${spot?.fleet?.unitNumber} - Occupied Full Trailer`
    } else if (spot.status == 'OCCUPIED' && spot?.fleet?.fleetStatus == 'EMPTY') {
      return `${spot.spotName} - ${spot?.fleet?.unitNumber} - Occupied Empty Trailer`
    } else if (spot.status == 'TO_BE_EMPTY') {
      return spot?.fleet == null ? `${spot.spotName} - Scheduled for Pick-Up` : `${spot.spotName} - ${spot?.fleet?.unitNumber} - Scheduled for Pick-Up`
    } else if (spot.status == 'TO_BE_OCCUPIED') {
      return spot?.fleet == null ? `${spot.spotName} - Location Reserved` : `${spot.spotName} - ${spot?.fleet?.unitNumber} - Location Reserved`
    } else {
      return `${spot.spotName}`
    }

  }

  showTrailerFields() {
    if (this.showHotTrailerNumberField == true) {
      this.showHotTrailerNumberField = false;
    } else {
      this.showHotTrailerNumberField = true;
    }

  }

  checkIfHotTrailer(fleet) {
    if (fleet.isHotTrailer) {
      return `${fleet.unitNumber} - (Hot Trailer)`
    } else {
      return `${fleet.unitNumber}`
    }
  }

  filterFleets(event) {

    this.manageFleetsService.viewFleets(this.query, this.clientId, event.query)
      .pipe(
        map(fleets => {
          let fleetsArray = [];
          for (let fleet of fleets.list) {
            let obj = {
              ...fleet,
              trailer: this.checkIfHotTrailer(fleet)
            };
            fleetsArray.push(obj);
          }
          return {
            list: fleetsArray
          }

        })
      ).subscribe(response => {
        this.filteredFleets = response.list;
        this.loader.hide();
      }, (error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
      });



  }

  onFleetSelect(event) {
    this.messageForm.patchValue({
      fleetId: event.fleetId,
      trailer: event.trailer
    });
  }

  onFleetSelect_jobForm(event) {
    this.jobForm.patchValue({
      fleetId: event.fleetId,
      trailer: event.trailer
    });
  }

  onUnitNumberChange(event) {
    console.log(event)
    this.messageForm.patchValue({
      fleetId: "",
    })
    // this.uniqueFleetId = "";
  }

  onUnitNumberChange_jobForm(event){
    this.jobForm.patchValue({
      fleetId: "",
    })
  }

  sentReply(message: any) {
    this.replyDialog = true;
    console.log("message", message);
    this.replyMessage = message;
    this.replyForm.patchValue({
      type: message.type,
      dropLocationId: message.dropLocation?.locationId,
      dropSpotId: message.dropSpot?.spotId,
      pickupLocationId: message.pickupLocation?.locationId,
      pickupSpotId: message.pickupSpot?.spotId,
      toUserIds: [message.fromUser?.userId],
      fleetId: message.fleet?.fleetId,
      carrier: message.fleet?.carrier,
      unitNumber: message.fleet?.unitNumber,
      clientLocationId: message.clientLocation?.locationId,
      sequenceAsn: message.sequenceAsn
    });

  }

  sendReplyMessage() {
    this.replySubmitted = true;
    if (this.replyForm.invalid) {
      this.replyForm.markAllAsTouched();
    } else {
      this.loader.show();
      this.manageMessagesService.sendMessage(this.replyForm.value).subscribe(res => {
        this.loader.hide();
        this.alertService.alertSuccess(['Message sent successfully']);
        // this.getMessages(this.query, null, this.inputOutbox);
        this.replyDialog = false;
        this.replyForm.reset();
        this.replySubmitted = false;
        // this.submitted = false;
      }, (error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
      });
    }
  }

  addSpot(message) {

    this.jobCheck = true;
    this.messapegPopupTitle = "Add Spot";
    
    // if(message.clientName != undefined){
    //   this.getClientLocations(this.drpQuery, message.pickupLocation?.clientId);
    //   this.getSpots(this.query, message.pickupLocation?.clientId, message.pickupLocation?.locationId, 'pickup');
    //   this.getSpots(this.query, message.pickupLocation?.clientId, message.dropLocation?.locationId, 'drop');
    // }

    this.jobForm.patchValue({
      sequenceAsn: message.sequenceAsn ? message.sequenceAsn : '',
      messageId: message.messageId ? message.messageId : '',
      replyUserId: message.fromUser?.userId ? message.fromUser?.userId : ''
    });

    if(message.clientName != undefined){
      this.jobForm.patchValue({
        clientId: message.pickupLocation?.clientId ? message.pickupLocation?.clientId : '',
        fleetId: message.fleet?.fleetId ? message.fleet?.fleetId : '',
        trailer: message.fleet?.fleetId ? this.checkIfHotTrailer(message.fleet) : '',
      });
      let pickupLoc = this.locationList.filter((x: any) => { return x.locationId == message.pickupLocation?.locationId });
      let dropLoc = this.locationList.filter((x: any) => { return x.locationId == message.dropLocation?.locationId });

      if(pickupLoc.length > 0){
        this.jobForm.patchValue({
          pickupLocationId: message.pickupLocation?.locationId ? message.pickupLocation?.locationId : ''
        });
        
        let pickupSpot = this.pickupSpots.filter((x: any) => { return x.spotId == message.pickupSpot?.spotId });
        if(pickupSpot.length > 0){
          this.jobForm.patchValue({
            pickupSpotId: message.pickupSpot ? message.pickupSpot.spotId : ''
          });
        }
      }

      if(dropLoc.length > 0){
        this.jobForm.patchValue({
          dropLocationId: message.dropLocation?.locationId ? message.dropLocation?.locationId : ''
        });

        let dropSpot = this.dropSpots.filter((x: any) => { return x.spotId == message.dropSpot?.spotId });
        if(dropSpot.length > 0){
          this.jobForm.patchValue({
            dropSpotId: message.dropSpot ? message.dropSpot?.spotId : ''
          });
        }
      }

  }

    console.log(this.jobForm.value);

  }

  hideReplyDialog() {
    this.replyDialog = false;
    this.replyForm.reset();
    this.replySubmitted = false;
  }

  getSpots(query, clientId, locationId, type) {
    this.loader.show();
    this.manageSpotsService.viewSpots(query, clientId, locationId)
      .pipe(
        map(spots => {
          let spotsArray = [];
          for (let spot of spots.list) {
            let obj = {
              ...spot,
              spotAndStatus: this.spotAndFleetStatus(spot)
            };
            spotsArray.push(obj);
          }
          return {
            list: spotsArray,
            totalElements: spots.totalElements
          }

        })
      ).subscribe(res => {
        type == 'pickup' ? this.pickupSpots = res.list : this.dropSpots = res.list;
        this.loader.hide();
      }, (error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
      })
  }

  hideJobDialog() {
    this.jobCheck = false;
    this.jobForm.reset();
    this.jobSubmitted = false;
    this.messapegPopupTitle = "Message";
  }

  addJob() {
    console.log("jobs", this.jobForm.value);
    console.log(this.jobForm);
    this.jobSubmitted = true;

    if (this.jobForm.invalid) {
      this.jobForm.markAllAsTouched();
    } else {
      // if(this.jobForm.value.assignedToUserId == "BUCKET_DRIVER" || this.jobForm.value.assignedToUserId == "BUCKET_SPOTTER"){
      //   this.jobForm.patchValue({
      //     bucket: this.jobForm.value.assignedToUserId,
      //     assignedToUserId: ""
      //   });
      // }else{
      //   this.jobForm.patchValue({
      //     bucket: "NIL"
      //   });
      // }
      this.addJobService.saveJob(this.jobForm.value).subscribe(res => {
        this.loader.hide();
        this.jobSubmitted = false;
        this.jobForm.reset();
        this.alertService.alertSuccess(['Spot Added Successfully']);
        this.jobCheck = false;
        this.readDialog = false;
        this.getMessages(this.query, null, this.inputOutbox);
      }, (error) => {
        // if(this.jobForm.value.bucket == "BUCKET_DRIVER" || this.jobForm.value.bucket == "BUCKET_SPOTTER"){
        //   this.jobForm.patchValue({
        //     assignedToUserId: this.jobForm.value.bucket,
        //     bucket: ""
        //   });
        // }
        this.loader.hide();
        this.errorService.handleError(error, true);
      })
    }
  }

  addFleet() {
    this.trailerSubmitted = true;
    if (this.newfleetForm.invalid) {
      this.newfleetForm.markAllAsTouched();
    } else {
      this.loader.show();
      this.addFleetService.saveFleet(this.newfleetForm.value).subscribe(res => {
        this.trailerSubmitted = false;
        this.addTrailerHeader = "Confirm";
        this.loader.hide();
        this.alertService.alertSuccess([`Added Successfully`]);
        this.messageForm.patchValue({
          fleetId: res.fleetId,
          trailer: res.unitNumber
        });
        this.addFleetDialogConfirm = false;
        this.addFleetDialog = false;
      }, (error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
      })

    }
  }

  addFleetConfirm() {
    this.addFleetDialogConfirm = true;
    this.addTrailerHeader = "Add Asset";
    this.newfleetForm.patchValue({
      unitNumber: this.messageForm.value.trailer,
      clientIds: [this.messageForm.value.clients],
      type: "TRAILER"
    })
  }

  cancelAddFleet() {
    this.trailerSubmitted = false;
    this.addTrailerHeader = "Confirm";
    this.newfleetForm.reset();
    this.addFleetDialogConfirm = false;
    this.addFleetDialog = false;
  }

  getCarrier(event) {

    console.log(event);

    this.addFleetService.getCarrier(event.query)
      .subscribe(response => {
        this.uniqueCarrierList = response;
        console.log(this.uniqueCarrierList);
        this.loader.hide();
      }, (error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
      });

  }

  clearCarrier() {
    this.uniqueCarrierList = null;
  }

  showNewTrailerFields(){
    if (this.isNewTrailer == true) {
      this.isNewTrailer = false;
      if (this.messageForm.get('type').value == 'TRAILER_TRUCK_MOVE') {
        this.messageForm.controls['fleetId'].setValidators([Validators.required]);
      } else {
        this.messageForm.controls['fleetId'].clearValidators();
      }
      this.messageForm.controls['fleetId'].updateValueAndValidity();
    } else {
      this.isNewTrailer = true;
      if (this.messageForm.get('type').value == 'TRAILER_TRUCK_MOVE') {
        this.messageForm.controls['fleetId'].setValidators([]);
      } else {
        this.messageForm.controls['fleetId'].clearValidators();
      }
      this.messageForm.controls['fleetId'].updateValueAndValidity();
    }
    this.messageForm.patchValue({
      trailerType: "TRAILER"
    });
  }

  onClientSelect(event) {
    this.jobForm.get('fleetId').reset(); 
    this.jobForm.get('trailer').reset(); 
    this.jobForm.get('pickupLocationId').reset(); 
    this.jobForm.get('dropLocationId').reset(); 

    this.getClientLocations(this.drpQuery, event.value);
    this.viewUsers(this.query, event.value);
    // this.getClientFleetList(this.query1, event.value);

  }

  onChange(event, type) {
    this.loader.show();
    this.manageSpotsService.viewDropdownSpots(this.query, this.jobForm.value.clientId, event.value)
      .pipe(
        map(spots => {
          let spotsArray = [];
          for (let spot of spots.list) {
            let obj = {
              ...spot,
              spotAndStatus: this.spotAndFleetStatus(spot)
            };
            spotsArray.push(obj);
          }
          return {
            list: spotsArray,
            totalElements: spots.totalElements
          }

        })
      ).subscribe(res => {
        type == 'pickup' ? this.pickupSpots = res.list : this.dropSpots = res.list;
        this.loader.hide();
      }, (error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
      })
  }
  // onTrailerChange(){
  //   setTimeout(() => {
  //     this.isInputTouched = true;
  //     // if((this.jobForm.value.fleetId == "" || this.jobForm.value.fleetId == undefined) && (this.jobForm.value.fleetAndHotTrailer != "" && this.jobForm.value.fleetAndHotTrailer != undefined)){
  //     //   this.addFleetDialog = true;
  //     // }
  //   }, 200);
    
  // }
  

}

